<template>
    <div class="share">
        <div class="header">
            <div class="warp">{{ $t('rewards') }}</div>
        </div>
        <div class="content">
            <div class="total-rewards"><i>{{ $t('invite_code') }}
                </i><i><em v-for="(item, i) in code">{{ item }}</em></i>
            </div>
            <tip>{{ $t('share_words') }}</tip>
            <base-btn class="capybut" @click="copy"
                data-clipboard-text="https://d1mv3tsb288bjw.cloudfront.net/CFLTraders/index.html">{{
                $t('copy_share') }}</base-btn>
            <router-link to="/reward">
                <div class="log">{{ $t('reward_list') }}</div>
            </router-link>
        </div>





        <Tabbar :now="'3'" />
    </div>
</template>

<script>
import { Dialog } from "vant";
import Tabbar from "../../components/tabbar.vue";
import Clipboard from "clipboard";
export default {
    components: { Tabbar },
    name: "AccountHis",
    data() {
        return {
            loading: false,
            code: ""


        };
    },
    created() {

        this.userinfo();

    },


    methods: {
        copy() {
            // 复制邀请码
            var clipboard = new Clipboard(".capybut");
            clipboard.on("success", e => {
                Dialog.alert({
                    message: "Copied successfully"
                }).then(() => {
                    clipboard.destroy(); // 释放内存
                });
            });
            clipboard.on("error", e => {
                //Toast("该浏览器不支持自动复制");
                clipboard.destroy(); // 释放内存
            });
        },
        userinfo() {
            this.get("user/info").then(res => {
                this.info = res.data;
                this.code = res.data.code

            })
        },


    }
};
</script>

<style lang="scss" scoped>
.share {
    background: #2c2175;
    min-height: 100vh;

    .content {
        box-shadow: 1px 2px 2px rgb(107, 106, 106);
        margin: 2rem;
        margin-top: 7rem;
        background: #fff;
        border-radius: 1rem;
        padding: 2rem;

        .log {
            display: block;
            text-align: center;
            color: #5f3ee2;
        }

        .total-rewards {
            i {
                display: flex;
                flex-wrap: wrap;
                font-size: 1.2rem;
                justify-content: space-evenly;
                font-style: normal;
                width: 100%;
                text-align: center;

                &:first-child {
                    margin-bottom: 2rem;
                    color: #5312f0;
                    font-size: 2rem;
                }

                &:last-child {
                    font-size: 4rem;
                }

                em {
                    font-size: 4rem;
                    font-style: normal;
                    padding: 1rem;
                    border-bottom: 1px solid #331572;
                    color: #331572;
                    font-weight: bold;

                }
            }
        }
    }

    .header {
        position: relative;
        height: 5rem;

        .warp {
            left: 0;
            top: 0;
            width: 100%;
            position: fixed;
            border-bottom: 1px solid #f2f2f2;
            position: fixed;
            left: 0;
            top: 0;
            text-align: center;
            background: #fff;
            width: 100%;
            color: #2b2e39;
            font-size: 1.5rem;
            font-weight: 700;
            height: 5rem;
            line-height: 5rem;
        }
    }
}
</style>