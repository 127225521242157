<template>
    <div class="AccountHis">
        <nav-bar>Dealer Center</nav-bar>



        <div class="lists">
            <div ref="recharge">
                <div role="feed" class="van-list">

                    <div class="recharge-item" v-for="(item, index) in rechargeList" :key="index">


                        <ul>
                            <li class="tp" :class="{ cr: item.amount > 0, dr: item.amount < 0 }">
                                {{
                                    item.amount > 0 ? "Cr" : "Dr"

                                }} <i>{{ item.transaction_type.title
}}</i></li>
                            <li class="amount">{{ formatToPrice(item.amount) }}</li>

                        </ul>


                        <ul>

                            <li>

                                Close Balance:
                                {{ formatToPrice(item.balance)
                                }}
                            </li>


                            <li class="time">Time:{{ item.created_at }}</li>

                        </ul>





                    </div>

                    <div class="nomare">-No more -</div>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
export default {
    name: "AccountHis",
    data() {
        return {
            current: 0,
            itemList: [{ name: "Recharge Details" }, { name: "Withdrawal" }],

            rechargeList: [],
            withdrawalList: [],
            status: 0,
            loading: false
        };
    },
    created() {
        this.chengeType(0);
    },

    methods: {
        formatToPrice(value) {

            return `₹${Number(value).toFixed(2)}`;
        },
        onClickLeft() {
            this.$router.go(-1);
        },
        chengeType(status) {
            this.status = status;
            if (status == 0) {
                this.getRechargeList();
            } else {
                this.getWithdrawalList();
            }
            this.current = status;
        },

        getRechargeList() {
            this.loading = true;
            let params = {};
            this.get("user/settlement").then(res => {
                this.rechargeList = res.data;
                setTimeout(() => {
                    this.loading = false;
                }, 100);
            });
        },



    }
};
</script>

<style lang="scss" scoped>
.lists {
    background: #fff;
    height: 100vh;
    overflow-y: scroll;
}

.loading {
    display: block;
    position: absolute;
    background: #fff;
    width: 100vw;
    height: 80vh;
    text-align: center;
    line-height: 80vh;
    z-index: 10;
}

p.success {
    color: #228b22 !important;
}

.recharge-item {
    padding: 1.5rem 1rem;

    border-bottom: 1px solid #f5f7f8;
    display: flex;
    flex-wrap: wrap;

    justify-content: space-between;



    ul {
        display: block;
        padding: 0.5rem 1rem;

        &:last-child {
            li {
                color: #949393;
                padding: 0.3rem 0;
                text-align: right;

                &.time {
                    padding: 1rem 0;
                    color: #d6d3d3;
                }


            }
        }

        li {
            i {

                text-align: left;
                font-style: normal;
                font-size: 0.8rem;
                color: #bcb9b9;
            }

            vertical-align: text-bottom;
            padding: 0.3rem 0;

            &.amount {
                float: right;
                padding-right: 1.8rem;
                color: #111729;
                font-size: 2rem;
            }

            &:first-child {


                &.cr {
                    color: #5bbf63;
                }

                &.dr {
                    color: #d64080;
                }
            }
        }










    }
}

.nomare {
    color: #969799;
    font-size: 14px;
    line-height: 50px;
    text-align: center;
}
</style>