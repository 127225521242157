<template>
	<div>
		<slot></slot>
	</div>
</template>
  
<script>
	export default {
		name: "def-input",
		data() {
			return {
				hasError: false,
				loacvalue: this.value,
				errs: false
			};
		},
		props: {
			label: String,
			value: String,
			roule: Object,
			placeholder: String,
			maxlength: String,
			disabled: String
		},
		mounted() {},
		methods: {
			submit() {
				let er = "";
				this.$children.forEach(item => {
					er += item.check();

					if (er != "") {
						return false;
					}
				});

				if (er != "") {
					return false;
				}
				return true;
			}
		}
	};
</script>

<style lang="scss" scoped>
</style>