<template>
	<div>

		<nav-bar>Account security</nav-bar>
		<div class="safes">
			<router-link to="changeloginpass">
				<li>
					Modify login password
					<img src="../../assets/imgs/profile/right.png" class="profile-sp-right" />
				</li>
			</router-link>
		</div>
	</div>
</template>

<script>
export default {
	name: "AccountHis",
	data() {
		return {
			current: 0
		};
	},
	created() { },
	mounted() { },
	methods: {
		onClickLeft() {
			this.$router.go(-1);
		}
	}
};
</script>

<style lang="scss" scoped>
.safes {
	li {
		img {
			width: 0.875rem;
			height: 1.625rem;
			float: right;
		}

		list-style: none;
		display: block;
		padding: 2rem 1rem;
		background: #fff;
		margin-top: 0.2rem;
	}
}
</style>