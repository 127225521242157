<template>
	<div class="AccountHis">
		<nav-bar>
			<div class="header">
				<li @click="chengeType(0)" :class="{ focus: status == 0 ? true : false }">{{ $t('create') }}</li>
				<li @click="chengeType(1)" :class="{ focus: status == 1 ? true : false }">{{ $t('payout') }}</li>
			</div>
		</nav-bar>


		<div v-show="loading" class="loading">
			<van-loading type="spinner" color="#1989fa" />
		</div>
		<div class="lists">
			<div v-show="current == 0" ref="recharge">
				<div role="feed" class="van-list">
					<div>
						<div class="recharge-item" v-for="(item, index) in rechargeList" :key="index">
							<div>
								<div>
									<ul>
										<li class="tp">{{ $t('sarlry') }}</li>
										<li class="status"
											:class="{ success: item.status == 1 ? true : false, unpaiid: item.status == 0 ? true : false }">
											{{ item.status === 0 ? "Unpaid" : "Created" }}</li>
									</ul>
									<ul>
										<li>{{ $t('time') }}:{{ item.created_at }}</li>
										<li class="amount">₹{{ item.Amount }}</li>
									</ul>


								</div>
							</div>
						</div>
					</div>

					<div class="nomare">-No more -</div>
				</div>
			</div>

			<div v-show="current == 1" ref="withdrawal" class="Recharge">
				<div role="feed" class="van-list">
					<div>
						<div class="recharge-item" v-for="(item, index) in 	withdrawalList" :key="index">
							<div>
								<div>
									<ul>
										<li class="tp">Ref:{{ item.order_num }}</li>
										<li class="status"
											:class="{ success: item.status == 3 ? true : false, unpaiid: item.status == 0 ? true : false }">
											{{ withdrawstatusFormat(item.status) }}
										</li>
									</ul>
									<ul>
										<li>{{ $t('time') }}:{{ item.created_at }}</li>
										<li class="amount">₹{{ item.amount }}</li>
									</ul>


								</div>
							</div>
						</div>
					</div>

					<div class="nomare">-No more -</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "AccountHis",
	data() {
		return {
			current: 0,
			itemList: [{ name: "Recharge Details" }, { name: "Withdrawal" }],

			rechargeList: [],
			withdrawalList: [],
			status: 0,
			loading: false
		};
	},
	created() {
		this.chengeType(0);
	},

	methods: {
		onClickLeft() {
			this.$router.go(-1);
		},
		chengeType(status) {
			this.status = status;
			if (status == 0) {
				this.getRechargeList();
			} else {
				this.getWithdrawalList();
			}
			this.current = status;
		},

		getRechargeList() {
			this.loading = true;
			let params = {};
			this.get("payout/salary_log").then(res => {
				this.rechargeList = res.data;
				setTimeout(() => {
					this.loading = false;
				}, 100);
			});
		},
		getWithdrawalList() {
			this.get("payout/salary_list").then(res => {
				this.withdrawalList = res.data;
			});
		},

		withdrawstatusFormat(status) {
			switch (status) {
				default:
					return this.$t('pending');
				case 0:
				case 1:
					return this.$t('pending');
				case 2:
					return this.$t('refund');
				case 4:
					return this.$t('pending');
				case 3:
					return this.$t('success');
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.lists {
	background: #fff;
	height: 100vh;
	overflow-y: scroll;
}

.loading {
	display: block;
	position: absolute;
	background: #fff;
	width: 100vw;
	height: 80vh;
	text-align: center;
	line-height: 80vh;
	z-index: 10;
}

.header {

	display: flex;
	justify-content: space-between;



	li {
		padding: 1rem 3rem;
		display: inline-block;


		border-radius: 1rem;

		list-style: none;

		color: #9aa3b5;
		background: #f8f9fd;
		font-size: 1rem;

		transition: all 0.2s ease-in-out;
		-webkit-transition: all 0.2s ease-in-out;
		margin-left: 1rem;


		&.focus {
			background: #5e3de2;

			color: #fff;

			transition: all 0.2s ease-in-out;
			-webkit-transition: all 0.2s ease-in-out;
			/* Safari */
		}
	}
}

p.success {
	color: #228b22 !important;
}

.recharge-item {
	padding: 1.5rem 1rem;

	border-bottom: 1px solid #f5f7f8;

	div {
		padding: 0;


		display: block;

		ul {
			display: block;
			padding: 0.5rem 0;

			li {
				&.amount {
					float: right;
					padding-right: 2rem;
					color: #111729;
					font-size: 2rem;
				}

				&.status {
					text-align: right;
					display: block;
					padding-right: 2rem;
					float: right;

					&.success {
						color: #5bbf63;

					}

					&.unpaiid {
						color: #f20101;
					}
				}


				display: inline-block;

				&:first-child {
					color: #cbcccd;

					&.tp {
						color: #525155;
					}
				}
			}









		}
	}
}

.nomare {
	color: #969799;
	font-size: 14px;
	line-height: 50px;
	text-align: center;
}
</style>