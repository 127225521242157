<template>
	<div>
		<nav-bar>LINK BANK ACCOUNT</nav-bar>
		<div class="safe">

			<base-form ref="from">
				<div>
					<base-input v-model="from.account_holder" disabled placeholder="Bank Account holder"></base-input>
				</div>
				<div>
					<base-input v-model="from.bank_account" maxlength="20" placeholder="Bank Account"></base-input>
				</div>
				<div>
					<base-input v-model="from.ifsc" maxlength="11" placeholder="IFSC"></base-input>
				</div>
				<div>
					<base-input v-model="from.bank_name" maxlength="20" placeholder="Bank Name"></base-input>
				</div>
			</base-form>
			<tip>The same
				account can only be bound to one account</tip>
			<div>
				<base-btn :loading="loading" @click="addBank">Submit</base-btn>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "AccountHis",
	data() {
		return {
			loading: false,
			current: 0,
			from: {
				bank_account: "",
				account_holder: "",
				ifsc: "",
				bank_name: ""
			}
		};
	},
	created() {
		this.getInfo()
	},
	mounted() { },
	methods: {
		getInfo() {
			this.get("user/info").then(res => {
				this.from.account_holder = res.data.name;
			});
		},
		addBank() {
			if (this.$refs.from.submit() == false) {
				return;
			}
			this.loading = true;
			this.post("bank/create", this.from).then(res => {
				this.$toast(res.info);
				this.loading = false;
			});
		},

		onClickLeft() {
			this.$router.go(-1);
		}
	}
};
</script>

<style lang="scss" scoped>
.safe {
	padding: 1rem;
	height: 90vh;
	background: #fff;

	div {
		margin: 1rem;
	}
}
</style>