<template>
    <div>
        <nav-bar>Identify Verification</nav-bar>


        <div class="safe">
            <div v-if="verifed" class="success"> <i>Status:</i><i>Verified Successfully</i></div>

            <base-form ref="from">
                <div>
                    <base-input disabled v-model="form.name" placeholder="Name"></base-input>
                </div>
                <div>
                    <base-input :disabled="verifed" v-model="form.adhar" maxlength="12"
                        placeholder="Adhar Card Number(12 digits)"></base-input>
                </div>
                <div>
                    <base-input :disabled="verifed" v-model="form.pan" maxlength="10"
                        placeholder="Pan Card Number(10  bits)"></base-input>
                </div>
                <div>
                    <base-input :disabled="verifed" v-model="form.birth_day" maxlength="8"
                        placeholder="Date of Birth(DDMMYYY)"></base-input>
                </div>
            </base-form>

            <div v-if="verifed == false">
                <base-btn :loading="loading" @click="add">Submit</base-btn>
            </div>

            <tip color="#1989fa">
                Please ensure that the information you fill in is true and valid, and that you are over 18 years old. The
                staff will review it manually. If you provide false information, your account will be blocked and the funds
                will not be returned</tip>


        </div>
    </div>
</template>

<script>
import AnimatedNumber from "animated-number-vue";

export default {
    components: { AnimatedNumber },
    name: "AccountHis",
    data() {
        return {
            loading: false,
            verifed: false,
            form: {
                name: "",
                pan: "",
                birth_day: "",
                adhar: "",



            }
        };
    },
    created() {
        this.getInfo();
        this.getDtail();

    },


    methods: {
        add() {
            this.loading = true;
            this.post("kyc/create", this.form).then(res => {
                this.$toast(res.info);
                this.loading = false;

            })
        },



        getInfo() {
            this.get("user/info").then(res => {
                this.form.name = res.data.name;
            });
        },
        getDtail() {
            this.get("kyc/detail").then(res => {
                if (res.data.id != 0) {

                    this.verifed = true;
                }
                this.form.adhar = res.data.adhar;
                this.form.pan = res.data.pan;
                this.form.birth_day = res.data.birth_day;
            });
        },


    }
};
</script>

<style lang="scss" scoped>
.success {
    margin: 3rem 0;
    display: flex;
    flex-wrap: wrap;
    font-size: 1.2rem;
    justify-content: flex-start;


    padding: 2rem 2rem;
    text-align: center;
    color: #fff;
    border-radius: 1rem;

    i {
        font-style: normal;
        padding: 0.5rem;

        &:last-child {
            border: 1px solid #28baa6;
            color: #28baa6;
            padding: 0.5rem;
            border-radius: 0.5rem;
            margin-left: 2rem;
        }
    }

}

.safe {
    padding: 2rem;

    background: #fff;




}
</style>