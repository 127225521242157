

export default {
    initchat() {
        this.initdb();
        this.ws_chat.conn();
        this.ws_chat.Sub(this.ChatMsg, "chat_list");
        this.get_admin();
        this.get_msg()
    },
    db_update(data) {
        return new Promise((resolve, reject) => {
            let request = this.db.transaction(['chat_list'], 'readwrite').objectStore('chat_list').put({ user_id: data.form_avatar.user_id, data: JSON.stringify(data) })
            request.onsuccess = event => {
                resolve(true)
            }
            request.onerror = er => {
                reject(false)
            }
        })

    },
    db_add(data) {
        let request = this.db.transaction(['chat_list'], 'readwrite').objectStore("chat_list").put({
            user_id: data.form_avatar.user_id,
            data: JSON.stringify(data)
        })
        request.onsuccess = evt => {
            console.log("添加成功", evt);
        }

    },
    db_select_id() {
        let _this = this
        return new Promise((resolve, reject) => {
            let request = _this.db.transaction(['chat_list']).objectStore(['chat_list'])
            let list = []


            request.openCursor().onsuccess = event => {


                let cursor = event.target.result
                if (cursor) {

                    list.push(JSON.parse(cursor.value.data))
                    cursor.continue()
                } else {
                    resolve(list)
                }
            }
            request.onerror = err => {

                reject(err)
            }


        })
    },
    initdb() {
        let request = indexedDB.open("chat2", 2)

        request.onerror = event => {
            console.log("数据库打开失败", event)
        }
        request.onupgradeneeded = evt => {
            let db = evt.target.result;
            let tab = db.createObjectStore("chat_list", {
                keyPath: "user_id"
            })
            tab.createIndex("data", "data", {
                unique: false
            })
            console.log("数据库升级")
        }
        request.onsuccess = evt => {
            this.db = evt.target.result;
            this.get_msg()
            console.log("数据库打开成功", evt)
            this.db_select_id().then(res => {
                console.log(res)
                // this.msgs = res

            })
        }

    },
    ChatMsg(data) {
        let user_id = data.form_avatar.user_id
        if (this.msg_map.has(user_id)) {
            let msg = this.msg_map.get(user_id)
            msg.count++
            msg.content = data.content
            msg.msg_id = data.msg_id;
            this.db_update(data)
            this.msg_map.set(user_id, msg)
        } else {
            this.msg_map.set(user_id, data)
            this.db_add(data)
        }

        // this.msgs.push(data)

    },
    chat(data) {

        let user_id = data.form_avatar.user_id
        localStorage.setItem("chat_box", JSON.stringify(data.form_avatar))
        let msg = this.$chat_msgs_lists.get(user_id)
        if (msg != undefined) {
            msg.count = 0
            this.msg_map.set(user_id, msg)
            this.last_msg = { time: new Date().getTime() }
        }
        this.$router.push({ path: "/chat_box" })


    },
    formatTime(time) {
        var now = new Date()


        var date = new Date(time * 1000);  // 参数需要毫秒数，所以这里将秒数乘于 1000
        var Y = date.getFullYear();
        var M = (date.getMonth() + 1 < 10 ? (date.getMonth() + 1) : date.getMonth() + 1);
        var D = date.getDate();
        var h = date.getHours();
        var m = date.getMinutes();

        if (D == now.getDate() && M == now.getMonth() + 1 && Y == now.getFullYear()) {
            return h + ":" + m
        } else {
            return Y + "/" + M + "/" + D
        }

    },
    getUserInfo() {
        return new Promise((resolve, reject) => {
            this.get("user/service").then(res => {
                //this.wa = res.data.wa;
                // this.channel = res.data.tg;
                resolve(res)
            });
        })
    },
    async get_admin() {

        let res2 = await this.getUserInfo()


        this.get_chat('admin_user', { user_names: res2.data.tg }).then(res => {
            res.data.forEach(val => {
                this.contanct_list.push({
                    count: 1,
                    msg_id: 0, content: 'May i help u?', form_avatar: {
                        icon: val.icon,
                        nick_name: val.nick_name,
                        user_id: val.id

                    },
                    send_time: new Date().getTime() / 1000
                })
            })
        })
    },
    get_msg() {
        this.get_chat("chat_msg_list", { plat: this.plat, last_msg_id: this.last_msg_id, to: this.to }).then(res => {

            res.data.forEach(val => {
                if (!this.$chat_msgs_lists.has(val.form_avatar.user_id)) {
                    this.$chat_msgs_lists.set(val.form_avatar.user_id, val)
                }
                this.db_add(val)
                this.last_msg = res.data[0];
            })
        })
    }
}