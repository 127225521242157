<template>
    <div class="container">


        <div class="chat-main">
            <div class="msg-list" v-if="tab == 0">
                <div class="msg-item" @click="chat(value)" v-for="(value, key) of build_msg" :key="key">
                    <div class="icon"><img :src="chat_host + '/' + value.form_avatar.icon">
                        <div class="unred" v-if="value.count > 0">{{ value.count }}</div>
                    </div>
                    <div class="content">
                        <div class="header">
                            <div class="nick_name">{{ value.form_avatar.nick_name }}
                            </div>
                            <div class="time">{{ formatTime(value.send_time) }}</div>
                        </div>
                        <div class="msg">{{ value.content }} </div>
                    </div>
                </div>
            </div>
            <div class="contact-list" v-if="tab == 1">
                <div class="msg-item" @click="chat(value)" v-for="(value, key) of  contanct_list" :key="key">
                    <div class="icon"><img :src="chat_host + '/' + value.form_avatar.icon">

                    </div>
                    <div class="content">
                        <div class="header">
                            <div class="nick_name_contact">{{ value.form_avatar.nick_name }}
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <div class="chat-footer">
                <div class="msg-list-icon" @click="tab = 0" :class="{ selected: tab == 0 }">

                    Msgs
                </div>
                <div class="contact-list-icon" @click="tab = 1" :class="{ selected: tab == 1 }">
                    Contacts
                </div>
            </div>
        </div>


        <Tabbar :now="'3'" />
    </div>
</template>

<script>
import Tabbar from "../../components/tabbar.vue";
import chat_list_mothod from './chat_list'
export default {
    name: "AccountHis",
    components: {
        Tabbar,
    },

    data() {
        return {
            contanct_list: [],
            tab: 0,
            msgs: new Array,
            last_msg_id: 0,
            msg_map: new Map,
            db: {},
            build_msg: [],
            last_msg: {}
        };
    },
    created() {
        this.initchat()
    },
    watch: {
        last_msg: function () {


            let arr = []
            this.$chat_msgs_lists.forEach(val => {
                arr.push(val)
            })
            arr.sort((a, b) => { return b.msg_id - a.msg_id })
            this.build_msg = arr;


        }
    },

    activated() {
        this.initchat()
    },

    methods: {
        ...chat_list_mothod

    },
};
</script>

<style lang="scss">
.chat-footer {
    padding-top: 0.5rem;

    position: fixed;
    width: 100vw;
    background: #f8f8f7;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-around;
    border-top: 1px solid #e9e8e7;

    div {
        padding: 1rem 2rem;

        &.selected {
            font-weight: bold;
            color: #cd3c0c;
            border-bottom: 2px solid #cd3c0c;
        }

    }







}

.chat-main {
    padding-top: 6rem;
    background: #fff;
    height: 90vh;


    .msg-item {
        position: relative;


        width: 100%;
        padding: 0.5rem;
        padding-top: 1rem;
        border-bottom: 1px solid #f7f7f6;

        display: flex;
        justify-content: space-around;

        .nick_name {
            font-size: 1.5rem;
            position: relative;

            .vvip {
                color: #fff;
                list-style: none;
                position: absolute;

                background: #0ccd90;
                padding: 0.1rem 0.5rem;
                border-radius: 1rem;
                font-size: 0.2rem;
                list-style: none;
                top: 0.2rem;
                left: 4rem;
            }
        }


        .icon {


            width: 5rem;
            height: 5rem;
            line-height: 5rem;
            background: #fff;
            text-align: center;
            border-radius: 2rem;
            color: #fff;

            img {
                width: 5rem;
                height: 5rem;
                border-radius: 5rem;
                border: 2px solid #e9e8e7;
            }

            .unred {
                position: absolute;
                right: 7rem;
                top: 1.2rem;
                background: red;
                color: #fff;
                line-height: 1.5rem;
                border-radius: 10rem;
                font-size: 1.5rem;
                min-width: 2rem;

            }
        }



        .content {
            width: 80vw;

            .header {

                display: flex;
                justify-content: space-between;

                .nick_name {
                    font-size: 2rem;

                }

                .nick_name_contact {
                    font-size: 2rem;
                    padding-top: 1rem;
                    padding-left: 1rem;

                }

                .time {
                    padding-right: 0.5rem;
                    color: #e9e8e7;
                }
            }

            .msg {
                padding-right: 0.5rem;
                color: #c4c3c2;
                padding-top: 0.2rem;
                font-size: 1.5rem;
            }
        }
    }


}
</style>