<template>
	<div class="base-input" v-bind:class="{ hasError: hasError }">
		<input :type="type" :disabled="disabled" @blur="check" :maxlength="maxlength" :placeholder="placeholder"
			v-model="loacvalue" @input="input" />

		<slot></slot>

	</div>
</template>

<script>
export default {
	name: "def-input",
	data() {
		return {
			hasError: false,
			loacvalue: this.value,
			errs: false
		};
	},
	watch: {

		value(newVal) {

			this.loacvalue = newVal;
		}
	},
	props: {
		label: String,
		value: String,

		placeholder: String,
		maxlength: String,
		disabled: String,
		type: String
	},

	methods: {
		check() {
			this.errs = false;
			this.hasError = false;
			return "";
			let reg = new RegExp(this.roule.reg);
			///console.log(reg, this.loacvalue);
			if (!reg.test(this.loacvalue)) {
				this.errs = true;
				this.hasError = true;
				return this.roule.msg;
			} else {
				this.errs = false;
				this.hasError = false;
				return "";
			}
		},
		input(event) {
			this.$emit("input", event.target.value);
		}
	}
};
</script>

<style lang="scss" scoped>
.otp {
	display: block;
	height: 40px;
	line-height: 40px;
	background: #fff;
}

$height: 6rem;

.base-input {
	margin: 0;
	border-radius: 6rem;
	display: block;
	position: relative;

	&.hasError {
		input {
			border-top: 1px solid #e34f68;
		}
	}

	::placeholder {
		color: #bfbfbf;
		font-size: 1.8rem;
	}

	height: $height;
	border: none;
	width: 100%;
	line-height: $height;

	.err {
		position: absolute;
		left: 3rem;
		top: 0.3rem;
		height: 1rem;
		line-height: 1rem;
		display: block;
		color: #e34f68 !important;
		font-size: 1rem;
	}

	input {
		box-sizing: border-box;
		caret-color: #0091fe;
		width: 100%;
		padding-left: 2rem;

		border: none;

		color: #333333;
		font-size: 2rem;

		border: none;

		height: 5.5rem;
		line-height: 5.5rem;
		border-radius: 1.5rem;

		background: #f8f9fd;
		border: 1px solid #fff;

		&:focus {
			border: 1px solid #6e54c1;
		}

	}
}
</style>