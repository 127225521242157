<template>
	<div class="change-pass">
		<van-nav-bar title="User Center" left-arrow @click-left="onClickLeft" />

		<div class="verif">
			<ul>
				<li>ID Verification</li>
				<li>
					<i :class="{ green: userinfo.verified == 1 ? true : false }">{{ userinfo.verified == 1 ? "Verified" :
						"Not Verified" }}</i>
				</li>
			</ul>
			<ul>
				<li>
					<img :src="face" />
				</li>
				<li>
					<button class="btn" id="pick-avatar">Change</button>
				</li>
				<avatar-cropper @uploaded="handleUploaded" upload-form-name="face_icon" :upload-headers="header"
					trigger="#pick-avatar" output-mime="image/jpeg" :labels="{ submit: 'SUBMIT', cancel: 'CANCEL' }"
					:upload-url="this.host + 'user/set_face_icon'" />
			</ul>
			<ul>
				<li>Mob</li>
				<li>{{ userinfo.mob }}</li>
			</ul>
			<ul>
				<li>Name</li>
				<li>{{ userinfo.name }}</li>
			</ul>
			<ul>
				<li>UID</li>
				<li>{{ userinfo.code }}</li>
			</ul>
		</div>
	</div>
</template>

<script>
import AvatarCropper from "vue-avatar-cropper";
export default {
	name: "AccountHis",
	components: { AvatarCropper },
	data() {
		return {
			loading: false,
			face: "../../assets/imgs/ok.png",

			info: {},
			userinfo: {},
			header: {
				token: localStorage.getItem("token")
			}
		};
	},
	created() {
		this.getInfo();
		this.getUserInfo();
	},

	methods: {
		handleUploaded(res) {
			this.$toast(res.info);
			//console.log(res);
			this.getUserInfo();
		},
		upload(data) {
			console.log(data);
		},
		getInfo() {
			this.get("/user/verification_info").then(res => {
				this.info = res.data;
			});
		},
		getUserInfo() {
			this.get("user/info").then(res => {
				this.userinfo = res.data;
				this.face = this.host + res.data.face_icon;
			});
		},
		verfi() {
			if (this.$refs.repass.submit() == false) {
				return;
			}
			this.loading = true;
			this.post("user/verification", this.form).then(res => {
				this.$toast(res.info);
				this.loading = false;
			});
		},
		onClickLeft() {
			this.$router.go(-1);
		}
	}
};
</script>

<style lang="scss" scoped>
.change-pass {
	background: #fff;
	height: 100vh;
}

.safe {
	div {
		margin: 1rem;
	}
}

.verif {
	background: #fff;

	ul {
		display: block;
		overflow: hidden;

		&:first-child {
			border-top: 1rem solid #edeff0;

			border-bottom: 1rem solid #edeff0;

			li {
				&:first-child {
					font-weight: bold;
					color: #000;
					float: left;
					display: block;
				}
			}
		}

		li {
			.btn {
				border: none;
				background: #fff;
				border: 1px solid #047df9;
				padding: 1rem;
				color: #000;
				border-radius: 0.5rem;
				margin-top: 4rem;
				float: right;
				font-weight: bold;
			}

			img {
				border: 5px solid #f5f7f8;
				width: 10rem;
				height: 10rem;
				border-radius: 10rem;
				float: left;
				margin-right: 1rem;
			}

			padding: 2rem 2rem;

			&:first-child {
				color: #b2b3b4;
				float: left;
				display: block;
			}

			&:last-child {
				float: right;
				display: block;
				font-weight: 700;
				color: #000;
			}

			i {
				position: relative;
				margin-right: 2rem;
				float: right;
				font-style: normal;
				font-weight: bold;

				&.green {
					color: #047df9;

					&:before {
						content: " ";
						background-size: contain !important;
						display: block;
						background: #fff url(../../assets/imgs/ok.png) no-repeat right;
						width: 2rem;
						height: 2rem;
						position: absolute;
						right: 6.5rem;
						top: 0rem;
						z-index: 1;
					}
				}
			}
		}
	}
}
</style>