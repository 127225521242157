<template>
	<div @touchstart.stop="touchstart" @touchmove.stop="touchmove" @touchend.stop="touchend"
		:style="{ height: '100%', width: '100vw' }">
		<canvas ref="line" :key="k" :style="{ height: '100%', width: '100vw' }"></canvas>
	</div>
</template>

<script>
import data from "./test";
export default {
	name: "kview",
	props: { height: String },
	mounted() {
		setTimeout(() => {
			this.k += 1;
			this.init();
		}, 10);
	},
	data() {
		return {
			k: 0,
			data: [],
			start: 0,
			end: 0,
			max: 0,
			min: 0,
			deaft: 30,
			w: 0,
			h: 0,
			dpr: false,
			draw_data: [], //k线数据
			vol: [], //成交量
			ma5: [],
			ma15: [],
			ma30: [],

			touch: false,
			ctx: null,
			move: 0,
			moveY: 0
		};
	},

	created() {
		this.data = data.data.data.reverse();
	},
	methods: {
		popstate() {
			history.pushState(null, null, document.URL);
		},
		touchmove(event) {
			let x = event.targetTouches[0].clientX;
			let y = event.targetTouches[0].clientY;
			if (Math.abs(x - this.move) < 5) {
				return;
			}

			if (event.touches.length == 2) {
				let c = Math.abs(
					event.touches[0].clientX - event.touches[1].clientX
				);
				if (c < 1) {
					return;
				}
				if (this.moveY > c) {
					this.start -= 1;
					this.end += 1;
				} else {
					this.start += 1;
					this.end -= 1;
				}
				this.moveY = c;
			} else {
				if (this.move < x) {
					this.start -= 1;
					this.end -= 1;
					//console.log("左边");
				} else {
					this.start += 1;
					this.end += 1;
					//console.log("右边----");
				}
			}

			this.move = x;
			this.init();
			if (this.start <= 0 || this.end >= this.data.length - 1) {
				return;
			}
			if (this.start >= this.data.length - 1 || this.end <= 0) {
				return;
			}
			if (this.end - this.start < 5) {
				return;
			}

			//this.touch = false;
		},
		touchend() {
			this.touch = false;
		},

		touchstart(event) {
			this.touch = true;
			//console.log(event.targetTouches[0].clientX);
		},
		initKline() {
			if (this.touch == false) {
				this.start = this.data.length - this.deaft;
				this.end = this.data.length - 1;
			}
			if (this.start <= 0) {
				this.start = 0;
			}
			if (this.end >= this.data.length) {
				this.end = this.data.length - 1;
				//return;
			}
			if (this.end - this.start < 20) {
				//return;
			}
			this.getMaxMin();
		},

		getMaxMin() {
			console.log(this.start, this.end);
			let max_arr = [];
			let min_arr = [];
			let vol_arr = [];
			this.draw_data = [];
			for (let i = this.start; i <= this.end; i++) {
				max_arr.push(this.data[i].high);
				min_arr.push(this.data[i].low);
				vol_arr.push(this.data[i].vol);
				this.draw_data.push(this.data[i]);
			}
			//this.draw_data = this.draw_data.reverse();
			this.vol.push(Math.max.apply(Math, vol_arr));
			this.vol.push(Math.min.apply(Math, vol_arr));
			this.max = Math.max.apply(Math, max_arr);
			this.min = Math.min.apply(Math, min_arr);
		},
		init() {
			let $_this = this;
			this.$nextTick(() => {
				let canvas = $_this.$refs.line;

				let dpr =
					"devicePixelRatio" in window ? window.devicePixelRatio : 1; // 假设dpr为2

				// 获取css的宽高
				let {
					width: cssWidth,
					height: cssHeight
				} = canvas.getBoundingClientRect();
				// 根据dpr，扩大canvas画布的像素，使1个canvas像素和1个物理像素相等
				//canvas.style.width = canvas.width + "px";
				//canvas.style.height = canvas.height + "px";
				canvas.width = dpr * cssWidth;
				canvas.height = dpr * cssHeight;
				$_this.w = cssWidth;
				$_this.h = cssHeight;

				// 由于画布扩大，canvas的坐标系也跟着扩大，如果按照原先的坐标系绘图内容会缩小
				// 所以需要将绘制比例放大
				let ctx = canvas.getContext("2d");
				ctx.translate(0.5, 0.5);
				ctx.scale(dpr, dpr);
				//ctx.scale(scale, scale);
				this.ctx = ctx;
				$_this.draw(ctx);
			});
		},
		drow_kd(c, max, min) {
			c.lineWidth = 1;
			c.strokeStyle = "#2d3246";
			let step = this.h / 6;
			let step2 = this.w / 6;
			let inter = (this.max - this.min) / 6;
			c.setLineDash([3, 3]);
			for (let i = 0; i < 6; i++) {
				c.fillStyle = "#465065";
				c.textBaseline = "bottom";
				c.fillText(
					(this.min + inter * i).toFixed(2),
					this.w - 50,
					step * i - 2
				);
				c.moveTo(0, step * i);
				c.lineTo(this.w, step * i);
			}
			for (let i = 0; i < 6; i++) {
				c.moveTo(step2 * i, 0);
				c.lineTo(step2 * i, this.h);
			}
			c.stroke();
			c.beginPath();
			c.lineWidth = 1;
			c.setLineDash([]);
			c.strokeStyle = "#64D4E3";
		},

		draw(c) {
			let draw_h = (this.h / 3) * 2;
			c = this.ctx;
			c.clearRect(0, 0, this.w, this.h);
			this.initKline();
			//设置样式
			this.drow_kd(c, this.max, this.min);

			c.lineWidth = 1;

			c.lineCap = "round";
			//c.fillStyle = gradient; //
			let x_interval = this.w / (this.end - this.start);
			let y_interval = (draw_h / (this.max - this.min)) * 0.8;
			let vol_y_interval =
				((draw_h - 100) / 2 / (this.vol[0] - this.vol[1])) * 0.1;

			this.draw_data.forEach((item, index) => {
				c.beginPath();

				let y1 = draw_h - (item.high - this.min) * y_interval;
				let y2 = draw_h - (item.low - this.min) * y_interval;

				c.moveTo(index * x_interval, y1);
				c.lineTo(index * x_interval, y2);

				if (item.close > item.open) {
					c.strokeStyle = "#28BAA6";
					c.fillStyle = "#28BAA6";

					c.fillRect(
						index * x_interval - x_interval / 2,
						draw_h - (item.close - this.min) * y_interval,
						x_interval * 0.8,
						(item.close - item.open) * y_interval
					);
					c.fillRect(
						index * x_interval - x_interval / 2,

						this.h - (item.vol - this.vol[1]) * vol_y_interval,
						x_interval * 0.8,
						(item.vol - this.vol[1]) * vol_y_interval
					);

					c.stroke();
				} else {
					c.strokeStyle = "#E34F68";
					c.fillStyle = "#E34F68";
					c.fillRect(
						index * x_interval - x_interval / 2,
						draw_h - (item.open - this.min) * y_interval,
						x_interval * 0.8,
						(item.open - item.close) * y_interval
					);

					c.fillRect(
						index * x_interval - x_interval / 2,

						this.h - (item.vol - this.vol[1]) * vol_y_interval,
						x_interval * 0.8,
						(item.vol - this.vol[1]) * vol_y_interval
					);

					c.stroke();
				}
				c.closePath();
			});

			//绘制
		},
		drawVol() { }
	}
};
</script>

<style lang="scss" scoped></style>