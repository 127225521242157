<template>
    <div class="container">
        <div class="nav-container">
            <nav-bar>
                <div class="nick_name">{{ Nick }}
                </div>
            </nav-bar>
        </div>
        <div class="msg-main">
            <div class="msg-contents">
                <div v-for="(item, index) in msgs" :key="index">
                    <div class="msg-friends" v-if="item.ref == 0">
                        <li></li>

                        <p class="msg" v-if="item.type == 'text'"> {{ item.content }}<i>{{
                    formatTime(item.send_time)
                }}</i>
                        </p>
                        <p class="msg" v-if="item.type == 'img'"><img @click="showimg(setUrl(item))"
                                :src="setUrl(item)" /><i>{{
                    formatTime(item.send_time)
                }}</i></p>
                        <p class="msg" v-if="item.type == 'file'"><b> <a _target="blank"
                                    :href="chat_host + '/' + item.url">{{
                    item.content
                }}</a></b><i>{{
                        formatTime(item.send_time)
                    }}</i>
                        </p>
                    </div>
                    <div class="msg-me" v-if="item.ref == 1">
                        <li></li>

                        <p class="msg" v-if="item.type == 'text'"> {{ item.content }}<i>{{ formatTime(item.send_time)
                                }}</i>
                        </p>
                        <p class="msg" v-if="item.type == 'img'"><img @click="showimg(setUrl(item))"
                                :src="setUrl(item)" /><i>{{
                    formatTime(item.send_time)
                }}</i></p>
                        <p class="msg" v-if="item.type == 'file'"><b> <a _target="blank"
                                    :href="chat_host + '/' + item.url">{{
                    item.content
                }}</a></b><i>{{
                        formatTime(item.send_time)
                                }}</i>
                        </p>


                    </div>
                </div>
            </div>

            <div class="msg-tool-bar">
                <div class="ipt"><textarea @keyup.enter="sendmsg" v-model="msg.content"></textarea></div>
                <div class="upload">
                    <input class="upload-input" type="file" ref="clearFile" @change="getFile($event)"
                        accept=".png,.jpg,.pdf,.jpeg,.zip,.gif,.mp4">


                    <img src="../../assets/chat/upload.png" />
                </div>

                <div :class="{ sending: sending == 0 }" class="send" @click="sendmsg">Send</div>

            </div>


        </div>
        <div class="msg-reader" v-if="reader_show">
            <img :src="show_img_url" @click="reader_show = false" />
        </div>
    </div>
</template>

<script>

import chat_box_method from './chat_box'
export default {

    name: "AccountHis",

    data() {
        return {
            reader_show: false,
            show_img_url: "",
            sending: 1,
            Nick: "",
            msgs: [],
            msg: {
                content: "",
                receiver: 0,
                plat: this.plat,
                target: "zh"
            },
            last_msg_id: 0,

            to: 0,


        };
    },
    created() {

        this.initChatBox();

    },
    activated() {
        this.initChatBox();


    },

    updated() {
        this.scrootobtn()
    },
    methods: {
        ...chat_box_method
    },
};
</script>
<style lang="scss" scoped>
.container {
    position: relative;
}

.msg-reader {
    display: table-cell;
    margin: 0 auto;
    vertical-align: middle;
    border: 1px solid #d5d5d6;

    text-align: center;
    padding: 0.5rem;
    left: 0;
    top: 3rem;
    position: absolute;
    z-index: 10000;
    width: 95vw;
    height: 81vh;
    line-height: 81vh;
    background: #fff;

    img {
        top: -50%;
        left: -50%;

        vertical-align: middle;
        max-height: 80vh;
        max-width: 80vw;

    }
}

::-webkit-scrollbar {
    display: none;
}

.nick_name {
    position: relative;
    color: #0c0c0c;
    font-size: 2rem;

    .vip {
        color: #fcfbfb;
        list-style: none;
        position: absolute;
        right: -2rem;
        top: 0;
        background: #f01d01;
        padding: 0.5rem 2rem;
        border-radius: 1rem;
        font-size: 1rem;
    }
}

.msg-tool-bar {
    padding: 1rem;
    display: flex;
    position: fixed;
    justify-content: space-evenly;
    bottom: 0;
    height: 4rem;
    width: 100vw;
    background: #f1f1f1;

    .ipt {
        width: 55vw;



        textarea {
            height: 2rem;
            padding: 1.2rem;
            border: none;
            width: 100%;
            background: #fff;
            border-radius: 0.5rem;
        }


    }

    .upload {
        position: relative;
        padding-left: 1.5rem;

        img {
            border-radius: 10rem;
            border: 1px solid #e9e8e7;
            height: 3.2rem;
            width: 3.2rem;
        }

        .upload-input {
            height: 4rem;
            width: 4rem;
            position: absolute;
            opacity: 0;
        }
    }

    .send {
        &.sending {
            background: #cccccc;
        }

        height: 4rem;
        background: #6ba5e0;
        width: 20vw;
        border-radius: 0.4rem;
        text-align: center;
        line-height: 4rem;
        color: #fff;
    }
}

.container {
    height: 95vh;
    overflow: hidden;
    background: #d0d6b3;
}

.msg-main {
    padding-top: 0.1rem;


    overflow: hidden;
    display: block;
    background: #d0d6b3;

    .msg-contents {
        display: block;
        padding: 2rem 1rem;
        background: #d0d6b3;
        height: 68vh;
        overflow-y: scroll;
        padding-bottom: 2rem;


        p {
            display: block;

            margin-top: 0.5rem;
            margin-bottom: 0.5rem;

        }

        .msg-friends {


            clear: both;
            display: block;
            position: relative;
            text-align: left;

            li {
                position: absolute;
                left: -0.7rem;
                width: 0rem;
                height: 0rem;
                top: 0.9rem;
                list-style: none;
                border-top: 0.7rem solid transparent;
                border-bottom: 0.7rem solid transparent;
                border-right: 0.7rem solid #fff;
            }


            .msg {
                position: relative;

                i {
                    float: right;
                    clear: both;
                    color: #a2aab4;
                    font-size: 1rem;
                    font-style: normal;
                    padding-left: 2rem;

                }

                img {
                    max-width: 10rem;
                    max-height: 10rem;
                }

                font-size: 1.8rem;

                float: left;
                max-width: 60vw;
                word-wrap: break-word;
                border-radius: 0.5rem;
                padding: 0.7rem 1rem;


                background: #fff;
                min-height: 1.5rem;

                b {
                    display: block;
                    background: url('../../assets/chat/file.png') no-repeat;
                    background-size: 2rem;
                    border-radius: 0.2rem;
                    padding-left: 2rem;
                    min-height: 3rem;
                    color: #c8c6c6;




                }

            }
        }

        .msg-me {

            clear: both;
            display: block;
            text-align: left;
            position: relative;

            li {
                position: absolute;
                right: -0.7rem;
                width: 0rem;
                height: 0rem;
                top: 1rem;
                list-style: none;
                border-top: 0.7rem solid transparent;
                border-bottom: 0.7rem solid transparent;
                border-left: 0.7rem solid #f6fde0;
            }

            .msg {
                i {
                    float: right;
                    clear: both;
                    color: #a2aab4;
                    font-size: 1rem;
                    font-style: normal;
                    padding-left: 2rem;

                }

                img {
                    max-width: 50vw;
                    max-height: 10rem;
                }

                b {
                    display: block;
                    background: url('../../assets/chat/file.png') no-repeat;
                    background-size: 2rem;
                    border-radius: 0.2rem;
                    padding-left: 2rem;
                    min-height: 3rem;
                    color: #c8c6c6;




                }

                font-size: 1.8rem;

                float: right;
                max-width: 60vw;
                word-wrap: break-word;
                border-radius: 0.5rem;
                padding: 0.7rem 1rem;


                background: #f3fddf;
                min-height: 1.5rem;


            }



        }
    }
}
</style>