<template>
	<div ref="main" class="main">
		<div ref="headers" class="header">
			<div class="warp">
				<div @click="onClickLeft">
					<img src="../../assets/bit/icon/arrow.png" />
				</div>
				<div class="name">{{ title }}({{ name }})</div>

				<div class="title"><router-link to="/recods"><img src="../../assets/bit/icon/receipt.png" /></router-link>
				</div>

			</div>
		</div>
		<div class="info" ref="info">
			<div class="item">
				<i class="name">{{ title }}({{ name }})</i>
				<li :class="{ call: detiel.zd > 0 ? true : false, put: detiel.zd <= 0 ? true : false }" class="price">{{
					detiel.price }}</li>
				<li>

					<i :class="{ call: detiel.zd > 0 ? true : false, put: detiel.zd <= 0 ? true : false }">{{ detiel.zd
					}}% </i>Past 24 Hours
				</li>
			</div>
			<div class="item">
				<img :src="host + '/static/icon/' + title + '.png'" />
			</div>
		</div>
		<div class="caves" :style="{ height: caves_height, width: '100vw' }">

			<kline ref="klines" :kdata="kdata" :height="caves_height"></kline>
			<div class="note">
				<i>{{ $t('call') }}</i>
				<i>{{ $t('put') }}</i>
			</div>
		</div>
		<div class="details" ref="detail">
			<div class="title">Market details</div>
			<div class="item">
				<div><i>{{ $t('open2') }}</i><i>{{ detiel.open }}</i></div>
				<div><i>{{ $t('vol') }}</i><i>{{ detiel.vol }}</i></div>
			</div>
			<div class="item">
				<div><i>{{ $t('heigh') }}</i><i>{{ detiel.high }}</i></div>
				<div><i>{{ $t('low') }}</i><i>{{ detiel.low }}</i></div>
			</div>

			<div class="btns">
				<div class="call" @click="order(0)">{{ $t('call') }}</div>
				<div class="put" @click="order(1)">{{ $t('put') }}</div>
			</div>
		</div>
		<van-popup class="td" :overlay="true" v-model="trade" position="bottom" :style="{ height: '50%', width: '100%' }">
			<div class="trade">
				<div class="title" :class="{ up: path == 0 ? true : false, down: path == 1 ? true : false }">{{
					title }}/USDT</div>

				<div class="path">
					<li>{{ $t('path') }}:</li>
					<li :class="{ call: path == 0 ? true : false, put: path == 1 ? true : false }">{{ path == 0 ? "CALL" :
						"PUT" }}</li>
				</div>

				<div>


					<base-input v-model="amount" placeholder="amount"
						:roule="{ reg: '^[0-9]{3,12}$', msg: 'Invild Amount' }"></base-input>



				</div>
				<div class="product">
					<div>
						<li>{{ $t('balance') }}:</li>
						<li>{{ balance }}</li>
					</div>
					<div>
						<li>{{ $t('rate') }}:</li>
						<li>{{ this.rate }}%</li>
					</div>


				</div>
				<base-btn @click="trde" :loading="loading">Confirm</base-btn>
			</div>
		</van-popup>




	</div>
</template>

<script>
import AnimatedNumber from "animated-number-vue";
import { Dialog } from "vant";
export default {
	name: "MarketList",
	components: { AnimatedNumber },
	data() {
		return {
			trdeTime: [],
			amount: "",
			path: 0,
			focus: 0,
			title: "",
			matket: false,
			caves_height: 100 + "px",
			caves_width: "300" + "px",
			loading: false,
			show: false,
			name: "",
			detiel: {
				price: 0,
				zd: 0,
				high: 0,
				low: 0,
				vol: 0,
				inr: 0
			},
			code: "",
			ktime: ["1min", "5min", "15min", "30min", "1day"],
			ktime_index: 0,
			kdata: [],
			symbol: "",
			balance: 0,
			product_list: [],
			trade: false,
			loading: false,
			rate: ""

		};
	},

	beforeRouteLeave(to, form, next) {
		this.ws.UnSub("kline");
		next();
	},

	created() {
		this.title = this.$route.query.name.toUpperCase();
		this.code = this.$route.query.code;
		this.name = this.$route.query.title;

		this.ws.Sub(this.marketData, "kline");

		this.symbol =
			"market." + this.code + ".kline." + this.ktime[this.ktime_index];
		this.getKline();
		this.getProductList();


	},
	mounted() {
		this.code = this.$route.query.code;
		let $loac = this;
		window.onresize = function windowResize() {
			setTimeout(() => {
				$loac.initCaves();
			}, 0);
		};
		setTimeout(() => {
			this.initCaves();
		}, 0);
	},
	activated() {

		this.ws.conn();
		this.title = this.$route.query.name.toUpperCase();
		this.code = this.$route.query.code;
		this.name = this.$route.query.title;

		this.ws.Sub(this.marketData, "kline");

		this.symbol =
			"market." + this.code + ".kline." + this.ktime[this.ktime_index];
		this.getKline();
		this.title = this.$route.query.name.toUpperCase();
		this.code = this.$route.query.code;
		this.getKline();
	},

	methods: {
		getinfo() {
			this.get("user/info").then(res => {
				this.balance = res.data.balance;
				this.amount = (this.balance * this.rate / 100).toFixed(0)

			})
		},

		order(path) {


			this.path = path;
			this.getinfo();
			this.get("trade/rate").then(res => {
				this.rate = res.data;
				this.amount = (this.balance * this.rate / 100).toFixed(0)
				this.trade = true;
			});
		},
		trde() {

			let parm = {
				code: this.code,
				path: this.path,
				amount: this.amount,
				//time: this.trdeTime[this.focus].val,
				//income: this.trdeTime[this.focus].profit
			};
			this.loading = true;
			this.post("trade/create", parm).then(res => {
				this.amount = "";
				Dialog.alert({
					message: res.info
				}).then(() => {
					if (res.status == 1) {
						this.getinfo();
						this.trade = true;
					}

					this.loading = false;


				});
			});
		},
		selectProduct(item) {
			this.title = item.title.toUpperCase();
			this.code = item.code;
			this.symbol =
				"market." +
				this.code +
				".kline." +
				this.ktime[this.ktime_index];
			this.getKline();
		},
		getProductList() {
			this.get("product/list").then(res => {
				let obj = {};
				res.data.forEach(item => {
					obj[item.code] = item;
					obj[item.code].price = 0;
					obj[item.code].vol = 0;
					obj[item.code].zd = 0;
				});
				this.product_list = obj;
			});
		},
		formatToPrice(value) {
			return `₹${Number(value).toFixed(2)}`;
		},

		getKline() {
			let parm = {
				symbol: this.symbol
			};
			this.get("market/kline", parm).then(res => {
				this.kdata = res.data;
				this.$refs.klines.Update();
				let sub = {
					sub: this.symbol
				};
				this.ws.sendData(sub);
			});
		},
		marketData(data) {
			//console.log(data);
			let code = data.ch.split(".");
			if (data.ch === this.symbol) {
				let lastId = this.kdata[this.kdata.length - 1].id;

				this.kdata.push(data.tick);

				this.$refs.klines.Update();
			} else {
				this.product_list[code[1]].price = data.tick.close;
				this.product_list[code[1]].vol = (
					((data.tick.amount * data.tick.close) / 1000).toFixed(2));
				this.product_list[code[1]].price;
				this.product_list[code[1]].zd = (
					((data.tick.close - data.tick.open) / data.tick.open) *
					100
				).toFixed(2);
				if (code[1] == this.code) {
					this.detiel.open = data.tick.open;
					this.detiel.price = data.tick.close;
					this.detiel.low = data.tick.low;
					this.detiel.high = data.tick.high;
					this.detiel.inr = (data.tick.close * 6.7 * 10.1).toFixed(2);
					this.detiel.vol = (
						(data.tick.amount * data.tick.close) /
						1000
					).toFixed(2) + "k";

					this.detiel.zd = (
						((data.tick.close - data.tick.open) / data.tick.open) *
						100
					).toFixed(2);
				}
			}
		},
		initCaves() {
			let pageHeight = window.innerHeight;
			let main = this.$refs.main.clientHeight;
			let info = this.$refs.info.offsetHeight;
			let btn = this.$refs.detail.offsetHeight;
			let header = this.$refs.headers.offsetHeight;

			let caves_height = pageHeight - info - btn - header - 10;

			this.caves_height = caves_height + "px";
			this.caves_width = this.$refs.main.clientWidth + "px";

		},

		onClickLeft() {
			this.$router.go(-1);
		},


	}
};
</script>

<style lang="scss" scoped>
html,
body {
	touch-action: none;
	height: 100%;
	overflow: hidden;
}

$call: #28baa6;
$put: #e34f68;
$def_bg: #fff;



.main {
	height: 100vh;
	background: $def_bg;
	overflow: hidden;
}

.trade {
	padding: 2rem;

	.title {
		color: #101729;
	}

	.product {
		display: flex;
		flex-wrap: wrap;

		justify-content: space-evenly;
		padding: 2rem 0;

		li {
			display: inline-block;
			list-style: none;

			&:first-child {
				color: #97a3b6;
			}

			&:last-child {
				padding-left: 1rem;
				color: #5f3ee2;
			}

		}
	}

	.path {

		padding: 2rem 0;

		li {
			display: inline-block;
			list-style: none;

			&:first-child {
				color: #97a3b6;
			}

			&:last-child {
				padding-left: 1rem;
				color: #5f3ee2;
			}

		}
	}

}

.header {
	height: 8vh;




	.warp {
		background: #fff;

		border-bottom: 1px solid #f0edff;
		height: 8vh;
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		font-size: 1.2rem;
		justify-content: space-between;

		div {
			.name {
				width: 10rem;
			}


			&:first-child {
				padding-left: 2rem;

				img {
					max-height: 3rem;
					max-width: 2rem;

				}
			}

			color: #111729;
			display: inline-block;
			height: 8vh;
			line-height: 8vh;

			&:last-child {

				text-align: right;
			}

			&.title {
				font-size: 2.4rem;
				font-weight: 500;

				text-align: right;
				padding-right: 2rem;
				margin-left: 2rem;

				img {
					max-height: 3rem;
				}
			}
		}
	}
}

.caves {
	margin: 5px 0;
	height: 200px;
	background: #fff;
	position: relative;

	.note {
		position: absolute;
		z-index: 1000;

		top: 3rem;
		left: 15rem;

		i {
			font-style: normal;
			color: #7e8da5;
			float: left;
			margin-left: 1rem;

			&:first-child {
				&::before {
					content: "";
					background: $call;
					display: block;
					width: 1rem;
					height: 1rem;
					border-radius: 1rem;
					float: left;
					margin-right: 0.5rem;
					margin-top: 0.5rem;
				}
			}

			&:last-child {
				&::before {
					content: "";
					background: $put;
					display: block;
					width: 1rem;
					height: 1rem;
					border-radius: 1rem;
					float: left;
					margin-right: 0.5rem;
					margin-top: 0.5rem;
				}
			}
		}
	}

	.time {
		position: absolute;
		background: #171b28;
		display: block;
		top: -1px;
		width: 100%;

		li {
			display: inline-block;
			color: #4a5e78;
			list-style: none;
			width: 25%;
			text-align: center;
			padding: 0.3rem 0;

			&.ac {
				color: #fff;
				border-bottom: 1px solid #6cb7a6;
			}
		}
	}
}


.info {




	background: $def_bg;
	padding: 0 2rem;

	i {
		font-style: normal;

		&.name {

			display: block;
			padding: 1rem 0;
			color: #677389;
		}
	}

	.item {
		vertical-align: middle;

		img {
			width: 7rem;
			height: 7rem;
			border-radius: 10rem;

		}

		&:first-child {
			li {
				&:first-child {
					height: 5rem;
					line-height: 5rem;
				}
			}
		}

		.price {
			font-size: 2em;
			font-weight: bold;
			padding: 1.5rem 0;
		}


		width: 50%;
		display: inline-block;

		li {
			overflow: hidden;
			height: 2.2rem;
			line-height: 2.2rem;
			padding: 0;
			color: #677288;
			display: block;

			i {
				font-size: 1.4rem;
				display: inline-block;

				&:first-child {
					color: #677288;

					text-align: left;
				}

				&.call {
					padding-left: 2rem;
					background: url("../../assets/bit/icon/up.png") no-repeat left center;
					background-size: 1.8rem 1.8rem;
					color: $call;
				}

				&.put {
					padding-left: 2rem;
					background: url("../../assets/bit/icon/down.png") no-repeat left center;
					background-size: 1.8rem 1.8rem;
					color: $put;
				}

				color: #c6cbda;


			}
		}

		&:last-child {
			text-align: right;
		}
	}
}

.details {
	padding-bottom: 10rem;
	display: block;
	position: relative;
	overflow: hidden;

	.title {
		display: block;
		color: #111729;
		padding: 1rem;

	}

	.btns {
		left: -2rem;
		bottom: 0;
		padding: 1rem;
		background: #fff;
		width: 100vw;

		position: fixed;

		display: flex;

		justify-content: space-evenly;


		div {

			border-radius: 1rem;
			color: #fff;

			padding: 1.5rem 7rem;

			display: inline-block;

			&.call {
				background: $call;
			}

			&.put {
				background: $put;
			}
		}
	}

	.item {
		padding: 1rem;

		div {
			border-bottom: 1px solid #e3e8ef;
			display: inline-block;
			overflow: hidden;

			width: 42%;
			margin-left: 2rem;

			i {
				overflow: hidden;
				font-style: normal;
				display: block;
				width: 48%;
				padding: 1rem 0;

				&:first-child {
					color: #677389;
					float: left;
				}

				&:last-child {
					float: right;
					font-style: normal;
					overflow: hidden;

					text-align: right;
					color: #111729;
				}
			}

		}
	}

}
</style>