<template>
	<div>
		<nav-bar>Transaction History</nav-bar>
		<div class="order-list">
			<div class="header">
				<li @click="chengeType(0)" :class="{ focus: status == 0 ? true : false }">{{ $t('trading') }}</li>
				<li @click="chengeType(1)" :class="{ focus: status == 1 ? true : false }">{{ $t('traded') }}</li>
			</div>
			<div class="lists">
				<div v-show="loading" class="loading">
					<van-loading type="spinner" color="#1989fa" />
				</div>

				<div class="mraket-list">
					<router-link :to="{ path: '/detail', name: 'detail', params: { data: item } }"
						v-for="(item, index) in order_list " :key="index">
						<div>

							<ul>
								<img :src="host + '/static/icon/' + item.title + '.png'" />
							</ul>

							<ul class="name">
								<i
									:class="{ red: item.path === 0 ? true : false, green: item.path === 0 ? false : true }">{{
					item.path === 0 ? "CALL" : "PUT" }}</i>
								<i>{{ item.title }}</i>


							</ul>
							<ul>
								<li>
									<i>{{ $t('posotion') }}</i>
									<i>₹{{ item.amount }}</i>
								</li>
							</ul>
							<ul>
								<li>
									<i>{{ $t('status') }}</i>
									<i
										:class="{ red: item.status === 1 ? true : false, green: item.status === 2 ? true : false }">{{
					getStatus(item.status) }}</i>
								</li>
							</ul>

							<ul>


								<li>
									<i>{{ $t('js') }}</i>
									<i>₹{{ item.status == 1 ? item.profit_amount : 0 }}</i>
								</li>
							</ul>

						</div>
					</router-link>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "MarketList",
	data() {
		return {
			order_list: [],
			status: 0,
			loading: false
		};
	},
	created() {
		this.getList();
	},

	methods: {
		getStatus(status) {
			let status2 = "";
			switch (status) {
				case 0:
					status2 = "Processing";
					break;
				case 2:
					status2 = "Loss";
					break;
				case 1:
					status2 = "Profit";
					break;
			}
			return status2;
		},
		chengeType(status) {
			this.status = status;
			this.getList();
		},
		getList() {
			this.loading = true;
			this.get("trade/list", { status: this.status }).then(res => {
				this.order_list = res.data;

				setTimeout(() => {
					this.loading = false;
				}, 100);
			});
		},
		onClickLeft() {
			this.$router.go(-1);
		}
	}
};
</script>

<style lang="scss" scoped>
.lists {
	position: relative;
	height: 1px;

	.loading {
		display: block;
		position: absolute;
		background: #fff;
		width: 100vw;
		height: 80vh;
		text-align: center;
		line-height: 80vh;
		z-index: 10;
	}
}

.order-list {
	height: 93vh;
	overflow-y: scroll;
}

$call: #28baa7;
$put: #e44e69;

.header {



	font-weight: bold;
	font-size: 1.8rem;
	background: #fff;

	border-bottom: 1px solid #e9e8e7;
	display: flex;
	justify-content: start;
	padding: 1rem 1rem;

	li {
		margin-left: 2rem;
		padding: 1rem 2rem;
		border-radius: 1rem;

		list-style: none;

		color: #9aa3b5;
		background: #f8f9fd;
		font-size: 1.7rem;

		transition: all 0.2s ease-in-out;
		-webkit-transition: all 0.2s ease-in-out;
		/* Safari */

		&.focus {
			background: #5e3de2;

			color: #fff;

			transition: op 1s ease-in-out;
			-webkit-transition: op 1s ease-in-out;
			animation: op 0.5s linear;
		}
	}
}

.mraket-list {
	div {
		padding: 1em;
		margin-top: 2px;
		background: #fff;
		background: #fff url("../../assets/bit/icon/chevron-right.png") no-repeat 41rem center;
		background-size: 2rem;
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;


		img {
			width: 4rem;
			height: 4rem;
			border-radius: 4rem;
		}

		.name {


			i {
				display: block;
				font-weight: bold;
				font-size: 1.6rem;
				margin-right: 1rem;
				font-style: normal;
				list-style: none;
				padding: 0.2rem 0;

				&.red {
					color: $call;
				}

				&.green {
					color: $put;
				}
			}
		}

		ul {
			vertical-align: middle;

			img {
				vertical-align: middle;
				width: 3rem;
				height: 3rem;
				border-radius: 3rem;
			}

			&:last-child {
				padding-right: 4rem;
			}

			li {



				vertical-align: middle;

				&:last-child {
					text-align: right;
				}

				display: inline-block;

				i {
					padding: 0.2em 0;

					&.red {
						color: $call !important;
					}

					&.green {
						color: $put !important;
					}

					font-style: normal;
					list-style: none;
					display: block;

					&:first-child {
						color: #97a2b6;
						font-size: 1.3rem;
					}

					&:last-child {
						font-size: 1.7rem;

						color: #111728;
					}
				}

				em {
					padding: 0.2rem 2rem;
					text-align: center;
					border-radius: 6px;
					font-size: 1.5rem;
					color: #fff;

					display: block;
				}


			}
		}
	}
}

@keyframes op {
	from {

		border-radius: 30rem;





		background: #e5084a;

	}

	to {



		border-radius: 5rem;


		background: #653ceb;

	}
}
</style>