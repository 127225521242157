<template>
	<div>
		<van-nav-bar :title="info.title" left-arrow @click-left="onClickLeft" />
		<div class="buy">
			<div class="title">{{info.title}}</div>

			<ul>
				<li>Lease time</li>
				<li>{{info.count_days}}Days</li>
			</ul>
			<ul>
				<li>Income Rate</li>
				<li class="income">{{100+info.in_come*info.count_days}}%</li>
			</ul>
			<ul>
				<li>Price</li>
				<li class="price">₹{{info.price}}</li>
			</ul>
			<div class="num">
				<base-input
					v-model="amount"
					type="number"
					class="input"
					maxlength="3"
					label="NUM:"
					:roule="{reg:'^[0-9]{1,3}$',msg:'Invild Num'}"
					placeholder="Number"
				></base-input>
			</div>
			<ul>
				<li>Available Balance</li>
				<li>₹{{balance}}</li>
			</ul>
			<ul>
				<li>TotalAmount</li>
				<li>₹{{totalAmout}}</li>
			</ul>
			<div class="btns">
				<base-btn :loading="loading" @click="pay">Buy</base-btn>
			</div>
		</div>
	</div>
</template>

<script>
	import { Dialog } from "vant";
	export default {
		name: "AccountHis",
		data() {
			return {
				title: "",
				info: {},
				balance: 0,
				totalAmout: 0,
				amount: "",
				loading: false
			};
		},
		watch: {
			amount(newvol) {
				this.totalAmout = newvol * this.info.price;
			}
		},
		created() {
			this.getInfo();
			this.getUserInfo();
		},

		methods: {
			getUserInfo() {
				this.get("user/info").then(res => {
					this.balance = res.data.balance;
				});
			},
			getInfo() {
				this.get("mining/info", { id: this.$route.query.id }).then(res => {
					this.info = res.data;
					this.totalAmout = res.data.price;
				});
			},
			onClickLeft() {
				this.$router.go(-1);
			},
			pay() {
				this.loading = true;
				let parm = { mining_id: this.$route.query.id, num: this.amount };
				this.post("mining/creat_order", parm).then(res => {
					Dialog.alert({
						message: res.info
					}).then(() => {
						this.amount = "";
						this.loading = false;
					});
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
.buy {
	background: #fff;
	height: 100vh;
	.btns {
		padding: 2rem;
	}
	.num {
		padding: 2rem;
	}
	.title {
		text-align: left;
		background: #fff;
		color: #000;
		border-bottom: 5px solid #f5f7f8;
		padding: 2rem;
	}
	ul {
		display: block;
		overflow: hidden;
		&:first-child {
			border-top: 1rem solid #edeff0;

			border-bottom: 1rem solid #edeff0;
			li {
				&:first-child {
					font-weight: bold;
					color: #000;
					float: left;
					display: block;
				}
			}
		}
		li {
			&.price {
				color: orangered !important;
			}
			.btn {
				border: none;
				background: #fff;
				border: 1px solid #047df9;
				padding: 1rem;
				color: #000;
				border-radius: 0.5rem;
				margin-top: 4rem;
				float: right;
				font-weight: bold;
			}
			img {
				border: 5px solid #f5f7f8;
				width: 10rem;
				height: 10rem;
				border-radius: 10rem;
				float: left;
				margin-right: 1rem;
			}
			padding: 2rem 2rem;
			&:first-child {
				color: #b2b3b4;
				float: left;
				display: block;
			}
			&:last-child {
				float: right;
				display: block;
				font-weight: 700;
				color: #000;
			}
			i {
				position: relative;
				margin-right: 2rem;
				float: right;
				font-style: normal;
				font-weight: bold;
				&.green {
					color: #047df9;
					&:before {
						content: " ";
						background-size: contain !important;
						display: block;
						background: #fff url(../assets/imgs/ok.png) no-repeat
							right;
						width: 2rem;
						height: 2rem;
						position: absolute;
						right: 6.5rem;
						top: 0rem;
						z-index: 1;
					}
				}
			}
		}
	}
}
</style>