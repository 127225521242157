<template>
  <div class="leader-board-container">
    <div class="top-container">
      <nav-bar>Invitation ranking</nav-bar>
      <div v-if="loading == true" class="loading">
        <van-loading type="spinner" color="#1989fa" />
      </div>
      <div class="tabs">
        <div @click="change(0, 'team')" :class="{ acc: selected == 0 }">Team Weekly</div>
        <div @click="change(1, 'team_month')" :class="{ acc: selected == 1 }">Team Monthly</div>
        <div @click="change(2, 'invite')" :class="{ acc: selected == 2 }"> L1 Weekly </div>
        <div @click="change(3, 'invite_moth')" :class="{ acc: selected == 3 }">L1 Monthly </div>
      </div>

      <div v-if="loading == false" class="ranking">
        <div class="ranking-one ranking-item">
          <div class="avatar">
            <img src="../../assets/ui5.0/avatar.png" alt="">
          </div>

          <div v-if="selected == 0" class="range"><i>{{ team_week[0].member.code }}</i>{{
        team_week[0].member.name }}
            <i> TEAM:{{ team_week[0].team
              }}</i>

          </div>
          <div v-if="selected == 1" class="range"><i>{{ team_week[0].member.code }}</i>{{
        team_week[0].member.name }}
            <i> TEAM:{{ team_week[0].team_month
              }}</i>

          </div>
          <div v-if="selected == 2" class="range"><i>{{ team_week[0].member.code }}</i>{{
        team_week[0].member.name }}
            <i> L1:{{ team_week[0].invite
              }}</i>

          </div>
          <div v-if="selected == 3" class="range"><i>{{ team_week[0].member.code }}</i>{{
        team_week[0].member.name }}
            <i> L1:{{ team_week[0].invite_moth
              }}</i>

          </div>
        </div>
        <div class="ranking-two ranking-item">
          <div class="avatar">
            <img src="../../assets/ui5.0/avatar.png" alt="">
          </div>

          <div v-if="selected == 0" class="range"><i>{{ team_week[1].member.code }}</i>{{ team_week[1].member.name }}
            <i> TEAM:{{ team_week[1].team
              }}</i>
          </div>
          <div v-if="selected == 1" class="range"><i>{{ team_week[1].member.code }}</i>{{ team_week[1].member.name }}
            <i> TEAM:{{ team_week[1].team_month
              }}</i>
          </div>
          <div v-if="selected == 2" class="range"><i>{{ team_week[1].member.code }}</i>{{ team_week[1].member.name }}
            <i> L1:{{ team_week[1].invite
              }}</i>
          </div>
          <div v-if="selected == 3" class="range"><i>{{ team_week[1].member.code }}</i>{{ team_week[1].member.name }}
            <i> L1:{{ team_week[1].invite_moth
              }}</i>
          </div>
        </div>
        <div class="ranking-three ranking-item">
          <div class="avatar">
            <img src="../../assets/ui5.0/avatar.png" alt="">
          </div>

          <div v-if="selected == 0" class="range"><i>{{ team_week[2].member.code }}</i>{{ team_week[2].member.name }}<i>
              TEAM:{{
        team_week[2].team
      }}</i></div>

          <div v-if="selected == 1" class="range"><i>{{ team_week[2].member.code }}</i>{{ team_week[2].member.name }}<i>
              TEAM:{{
        team_week[2].team_month
      }}</i></div>
          <div v-if="selected == 2" class="range"><i>{{ team_week[2].member.code }}</i>{{ team_week[2].member.name }}<i>
              L1:{{
        team_week[2].invite
      }}</i></div>
          <div v-if="selected == 3" class="range"><i>{{ team_week[2].member.code }}</i>{{ team_week[2].member.name }}<i>
              L1:{{
        team_week[2].invite_moth
      }}</i></div>

        </div>
      </div>
      <div class="prize-fixed" @click="navPrizeRoute">Prize</div>

    </div>
    <div v-if="loading == false" class="ranking-list-container">
      <div class="ranking-list-title">
        <div class="ranking-list-title-item">Ranking</div>
        <div class="ranking-list-title-item">Name(code)</div>
        <div v-if="selected <= 1" class="ranking-list-title-item">Team</div>
        <div v-if="selected > 1" class="ranking-list-title-item">L1</div>

      </div>
      <div class="ranking-list-item-container">
        <div class="list-item" v-for="(item, index) in team_week" :key="index" v-if="index > 2">
          <div class="ranking-list-item">{{ index + 1 }}</div>
          <div class="ranking-list-item code">
            <i>{{ item.member.name.charAt(0) }}</i>
            <span>{{ item.member.name }}({{ item.member.code }})</span>
          </div>
          <div v-if="selected == 0" class="ranking-list-item">{{ item.team }}</div>
          <div v-if="selected == 1" class="ranking-list-item">{{ item.team_month }}</div>
          <div v-if="selected == 2" class="ranking-list-item">{{ item.invite }}</div>
          <div v-if="selected == 3" class="ranking-list-item">{{ item.invite_moth }}</div>

        </div>

      </div>
    </div>
  </div>
</template>
<script>

export default {

  data() {
    return {
      loading: false,
      selected: 0,
      activeIndex: 0,
      team_week: [],
    }
  },
  created() {
    this.get_pm("team")
  },
  methods: {
    change(vals, faild) {
      this.selected = vals;
      this.get_pm(faild)
    },
    get_pm(val) {
      this.loading = true
      this.get("user/ranking", { filed: val }).then(res => {
        this.team_week = res.data;
        this.loading = false;
      })
    },
    navPrizeRoute() {
      this.$router.push({ path: '/rw' })
    }
  }
}
</script>
<style scoped lang="scss">
.loading {
  padding-top: 10rem;
  text-align: center;
  background: #fff;
  height: 100vh;
  width: 100vw;
  margin: 0 auto;
}

.leader-board-container {
  position: relative;
}

.tabs {
  background: #fff;
  width: 100vw;
  height: 5rem;


  position: fixed;
  display: flex;
  left: 0;
  bottom: 0;
  justify-content: space-around;
  border-top: 1px solid #f3f3f4;

  div {
    padding: 0 1rem;
    border-radius: 2rem 2rem 2rem 0rem;
    opacity: 0.7;
    color: #a8a0f7;

    &.acc {
      font-weight: bold;
      background: #333eb9;
      color: #fff;
      border-bottom: 2px solid #333eb9;
    }

    text-align: center;
    line-height: 5rem;
  }

}

.leader-board-container {
  background: url("../../assets/ui5.0/top-bg.png") no-repeat top center / 100% 24.81rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .top-container {
    height: 22.81rem;
    position: relative;

    .tabs {
      margin: 0 0.41rem;

      :deep(.van-tabs) {
        .van-tabs__wrap {
          height: 3rem;
        }
      }

      :deep(.van-tabs__nav) {
        background: #293772;
        border-radius: 6px;
        border: 0 none;
        height: 3rem;
        margin: 0;

        .van-tab {
          color: #aeb0c2;
          border: 0 none;

          .van-tab__text {
            color: #ffffff80;
            font-size: 0.8rem;
          }
        }

        .van-tab--active {
          background: linear-gradient(to right, #1D52FF 0%, #A42EFF 99%), #2E3373;
          border-radius: 6px;

          .van-tab__text {
            color: #fff;
          }
        }

        .van-tab__text--ellipsis {
          overflow: unset;
          text-align: center;
          -webkit-line-clamp: unset;
        }
      }

    }

    .ranking {
      .ranking-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 7rem;
        position: absolute;

        .name {
          padding-top: .5rem;
          color: #dcdcdc;
          font-size: 2rem;
        }

        .range {
          i {
            display: block;
            font-style: normal;
            color: #fff;
          }

          padding: 1rem;
          background: #3241cc;
          border-radius: 1rem;
          color: #dcdcdc;
          font-size: 1.6rem;
          opacity: 0.8;
        }
      }

      .ranking-one {
        top: 36%;
        left: 50%;
        transform: translateX(-50%);

        .avatar {
          width: 6.66rem;
          height: 5.16rem;
          position: relative;

          img {
            position: absolute;
            top: 1.4rem;
            left: 2rem;
            width: 3.2rem;
            height: 3.2rem;
          }

          background: url("../../assets/ui5.0/top1.png") no-repeat center center / 100% 100%;
        }
      }

      .ranking-two {
        top: 45%;
        left: 20%;
        transform: translateX(-50%) scale(.8);

        .avatar {
          width: 6.66rem;
          height: 5.16rem;
          position: relative;

          img {
            position: absolute;
            top: 1.6rem;
            left: 1.85rem;
            width: 2.9rem;
            height: 2.9rem;
          }

          background: url("../../assets/ui5.0/top2.png") no-repeat center center / 100% 100%;
        }
      }

      .ranking-three {
        top: 50%;
        left: 81%;
        transform: translateX(-50%) scale(.8);

        .avatar {
          width: 6.66rem;
          height: 5.16rem;
          position: relative;

          img {
            position: absolute;
            top: 1.6rem;
            left: 1.85rem;
            width: 2.9rem;
            height: 2.9rem;
          }

          background: url("../../assets/ui5.0/top3.png") no-repeat center center / 100% 100%;
        }
      }
    }
  }

  .ranking-list-container {
    flex: 1;
    background: #fff;
    border-radius: 6px 6px 0 0;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .ranking-list-title {
      display: flex;
      justify-content: space-between;
      padding: 0 1rem;

      .ranking-list-title-item {
        color: #babfd5;
        font-size: 1.5rem;
        padding: 2rem 0;
        min-width: 4rem;
      }
    }

    .ranking-list-item-container {
      flex: 1;
      padding: 0 1rem;
      overflow: auto;

      .list-item {
        border-bottom: 1px solid #ebebeb;
        display: flex;
        justify-content: space-between;
        padding: 2rem 1rem;


        &:first-child {
          padding-top: 0;
        }

        .ranking-list-item {
          &:first-child {
            color: #a49e9e;
            font-size: 2rem;


            border-radius: 4rem;
            padding: 0 1rem;

          }

          &:last-child {
            color: #2b3bbe;
            font-size: 2rem;
            font-weight: bold;
          }

          &.code {
            i {
              width: 6rem;
              height: 6rem;
              background: #d2dcf9;
              text-align: center;
              line-height: 6rem;
              color: #2835bc;
              border-radius: 3rem;
              font-style: normal;
              font-weight: bold;
              font-size: 3rem;
            }

            width: 30rem;
          }

          display: flex;
          align-items: center;
          justify-content: start;

          span {
            margin-left: 0.8rem;
          }

          img {
            width: 4rem;
            height: 2.8rem;
          }
        }
      }
    }

  }
}

.prize-fixed {
  opacity: 0.8;
  position: fixed;
  background: linear-gradient(-45deg, #1D52FF 0%, #A42EFF 99%), #2E3373;
  border-radius: 0 8rem 8rem 0;
  width: 8rem;
  height: 6rem;
  color: #fff;
  text-align: center;
  line-height: 6rem;
  left: 0;
  top: 85%;

}
</style>