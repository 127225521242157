import request from './utils/request';
function dateFormat(fmt, date) {
    let ret;
    const opt = {
        "Y+": date.getFullYear().toString(),        // 年
        "m+": (date.getMonth() + 1).toString(),     // 月
        "d+": date.getDate().toString(),            // 日
        "H+": date.getHours().toString(),           // 时
        "M+": date.getMinutes().toString(),         // 分
        "S+": date.getSeconds().toString()          // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
            fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        };
    };
    return fmt;
}
export const start_time = () => {
    let now = new Date();
    let start = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
    return dateFormat("YYYY-mm-dd HH:MM:SS", start)

}
export const end_time = () => {
    let now = new Date();
    let start = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
    return dateFormat("YYYY-mm-dd HH:MM:SS", start)

}

export const post = (url, params) => {

    return request({
        url: url,
        method: 'POST',
        data: params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            token: localStorage.getItem("token"),
            lang: "en"
        },
        transformRequest: [function (data) {
            let ret = ''
            for (let it in data) {
                ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
            }
            return ret
        }],

    });
}

export const get = (url, params) => {

    return request({
        url: url,
        method: 'GET',
        params: params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            token: localStorage.getItem("token"),
            lang: "en"
        },

    });
}