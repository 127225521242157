<template>
	<div class="AccountHis">
		<van-nav-bar :title="title" left-arrow @click-left="onClickLeft" />
		<div class="pageinfo">
			<iframe :src="url"></iframe>
		</div>
	</div>
</template>

<script>
	export default {
		name: "AccountHis",
		data() {
			return {
				content: "loading",
				title: "",
				url: ""
			};
		},
		created() {
			this.url = localStorage.getItem("url");
			console.log(this.url);
		},
		mounted() {
			this.url = localStorage.getItem("url");
		},

		methods: {
			onClickLeft() {
				this.$router.go(-1);
			}
		}
	};
</script>

<style lang="scss" scoped>
.pageinfo {
	padding: 1rem;
	background: #fff;
	height: 90vh;
	color: #000;
	overflow-y: scroll;
	iframe {
		border: none;
		width: 100vw;
		height: 90vh;
	}
}
</style>