<template>
    <div class="container">
        <nav-bar>Signal Channel</nav-bar>
        <div class="msg-content">



            <div v-for="(item, index) in msgs" :key="index">
                <div class="item" v-html="item.msg"></div>
                <div class="time">{{ item.created_at }}</div>


            </div>

        </div>


        `
    </div>
</template>

<script>
import tabbar from '../components/tabbar.vue';

export default {
    name: "AccountHis",
    components: {
        tabbar,
    },
    data() {
        return {
            msgs: []
        };
    },
    created() {

        this.getMsg()
    },
    activated() {
        this.msgs = []
        this.getMsg()
    },
    methods: {





        getMsg() {
            this.get("channel/list").then((res) => {
                this.msgs = res.data;
            });
        },
    },
};
</script>

<style lang="scss">
.title2 {
    width: 100vw;
    background: #fff;
    position: fixed;
    left: 0;
    color: #383839;
    top: 0;
    text-align: center;
    font-weight: bold;
    border-bottom: 1px solid #e9e8e7;
    display: block;
    padding: 2rem 0
}

.msg-content {
    padding-top: 6rem;
    background: #f8f8f7;
    height: 90vh;
    overflow-y: scroll;

    .time {
        font-size: 1rem;
        padding: 0;
        text-align: center;
        color: #b6b6b6;
        background: #f8f8f7;
    }



    .item {
        img {

            max-width: 100%;
        }

        border: 1px solid #ededed;

        margin: 1rem;
        border-radius: 0.5rem;
        display: block;
        padding: 1.5rem;
        background: #fff;



        p {
            color: #000;
            margin: 0 !important;
            margin-block-start: 0 !important;
            margin-block-end: 0 !important;
            padding: 0;
            line-height: 150%;
        }
    }
}
</style>