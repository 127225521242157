<template>
    <div class="warp">
        <div class="nav-bar">
            <div class="back" @click="onClickLeft"></div>


            <div class="content">
                <slot></slot>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: "def-input",
    data() {
        return {

        };
    },
    watch: {

    },


    methods: {
        onClickLeft() {
            this.$router.go(-1);
        },

    }
};
</script>

<style lang="scss" scoped>
.warp {
    width: 100%;
    height: 6rem;
    z-index: 1000000;
}

.nav-bar {
    z-index: 1000000;
    text-overflow: ellipsis;
    align-items: center;

    background: #fff;
    position: fixed;
    width: 100%;

    display: flex;
    height: 6rem;
    overflow: hidden;
    border-bottom: 1px solid #f0edff;





    div {





        display: inline-block;

        &.back {
            height: 6rem;

            padding-right: 2rem;
            width: 5rem;
            background: url("../assets/bit/icon/arrow.png") no-repeat 2rem center;

            background-size: 1.5rem;
        }

        &.content {
            padding-right: 7rem;
            text-align: center;
            width: 100%;
            font-weight: 700;
            color: #101729;
        }


    }
}
</style>