<template>
	<div class="trdepage">
		<div class="header">{{ $t('market') }}</div>
		<div class="serch">
			<input :placeholder="$t('serch')">
		</div>
		<div class="bk">
			<li class="ac">Storage</li>
			<li>Women-led</li>
			<li>Gaming</li>
			<li>NFT</li>
			<li>POW</li>
		</div>


		<div v-for="(item, index) in product_list" :key="index" class="mraket-list">
			<router-link :to="{ path: '/kline', query: { code: item.code, name: item.title, title: item.name } }">
				<ul :key="key">
					<img :class="{ shake: item.price != 0 }" :src="host + '/static/icon/' + item.title + '.png'" />
					<li class="w35">
						<i>{{ item.title }}</i>
						<i>{{ item.name }}</i>
					</li>

					<li>
						<i>{{ item.price }}</i>
						<i :class="{ red: item.zd < 0 ? true : false, green: item.zd >= 0 ? true : false }">{{ item.zd > 0 ?
							"+" : "" }}{{ item.zd }}%</i>
					</li>

				</ul>
			</router-link>
		</div>
		<Tabbar :now="'2'" />
	</div>
</template>

<script>
import Tabbar from "../components/tabbar.vue";
import TopHeader from "./header.vue";

export default {
	name: "Trde",
	components: {
		Tabbar,
		TopHeader
	},
	created() {
		this.getProductList();
		this.ws.Sub(this.marketData, "list");
	},
	data() {
		return {
			key: 0,
			data: {},
			product_list: {}
		};
	},
	activated() {
		this.ws.conn();
	},
	methods: {
		marketData(data) {
			let code = data.ch.split(".");
			if (code.length != 3) {
				return;
			}
			//console.log(code);
			this.product_list[code[1]].price = data.tick.close;
			this.product_list[code[1]].vol = (
				((data.tick.amount * data.tick.close) / 100000000) *
				6.7 *
				10.1
			).toFixed(2);
			this.product_list[code[1]].price;
			this.product_list[code[1]].zd = (
				((data.tick.close - data.tick.open) / data.tick.open) *
				100
			).toFixed(2);
			//this.k++;
			//console.log(this.data);
			//console.log(data.ch);
		},
		getProductList() {
			this.get("product/list").then(res => {
				let obj = {};
				res.data.forEach(item => {
					obj[item.code] = item;
					obj[item.code].price = 0;
					obj[item.code].vol = 0;
					obj[item.code].zd = 0;
				});
				this.product_list = obj;
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.bk {
	padding: 1rem 2rem;
	display: flex;
	flex-wrap: wrap;
	font-size: 1.2rem;
	justify-content: space-evenly;

	li {
		padding: 1rem;
		border-radius: 0.9rem;
		list-style: none;
		background: #f8f9fd;
		color: #97a2b6;

		&.ac {
			background: #643beb;
			color: #fff;
		}
	}
}

.serch {
	margin-top: 4rem;
	padding: 2rem;
	width: 100%;

	input {
		background: #f8f9fd url("../assets/bit/icon/Search.png") no-repeat 1rem center;
		background-size: 2.5rem;
		padding: 1.5rem 2rem;
		padding-left: 4rem;
		border: none;
		width: 35rem;
		border-radius: 1rem;
		font-size: 2rem;

		&::placeholder {

			color: #97a2b6;
		}

		border: 1px solid #fff;

		&:focus {

			border: 1px solid #6e54c1;
		}

	}
}

.header {
	border-bottom: 1px solid #f2f2f2;
	position: fixed;
	left: 0;
	top: 0;
	text-align: center;
	background: #fff;
	width: 100%;
	color: #2b2e39;
	font-size: 1.5rem;
	font-weight: 700;
	height: 5rem;
	line-height: 5rem;
}

.shake {
	animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
	transform: translate3d(0, 0, 0);
}

@keyframes shake {

	10%,
	90% {
		transform: translate3d(-1px, 0, 0);
	}

	20%,
	80% {
		transform: translate3d(2px, 0, 0);
	}

	30%,
	50%,
	70% {
		transform: translate3d(-4px, 0, 0);
	}

	40%,
	60% {
		transform: translate3d(4px, 0, 0);
	}
}



.trdepage {
	height: 100vh;
	background: #fff;
}

.header {
	text-align: center;
	height: 5rem;
	line-height: 5rem;
	background: #fff;
}

.mraket-list {
	background: #ffffff;

	ul {
		margin: 0.1rem 0rem;
		border-bottom: 1px solid #f5f6f7;
		//border-radius: 1rem;
		padding-left: 2rem;

		padding: 0.2rem 2rem;

		img {
			width: 4rem;
			height: 4rem;
			border-radius: 3rem;
			border: 1px solid #e9e8e7;
			margin-right: 1rem;
			vertical-align: middle;
		}

		li {
			width: 40%;

			vertical-align: middle;

			padding: 0.4rem 0.2rem;
			margin-right: -4px;
			display: inline-block;

			&:last-child {
				i {
					text-align: right;
				}
			}

			i {
				padding: 0.3rem 0;

				font-style: normal;
				list-style: none;
				display: block;

				&:first-child {
					font-size: 1.8rem;
					font-weight: 500;
					color: #111729;
					font-weight: 400;
				}

				&:last-child {

					color: #677389;
					font-size: 1.3rem;
					font-weight: 400;

				}

				&.red {
					color: #e34f68;
					background: url("../assets/bit/icon/down.png") no-repeat 9rem center;
					background-size: 1.6rem 1.5rem;
				}

				&.green {
					color: #28baa6;
					background: url("../assets/bit/icon/up.png") no-repeat 9rem center;
					background-size: 1.5rem 1.5rem;
				}
			}

			&last-child {
				float: right;
			}
		}
	}
}
</style>
