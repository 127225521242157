<template>
	<div class="tabbar">

		<div class="tabbar-item" v-for="item of list" :key="item.value" @click="handleChange(item.path)">
			<div class="icon" :class="{ ac: item.value == now }">
				<div v-if="item.value == 3 && total_msg > 0" class="unred-msg">{{ total_msg }}</div>
				<span :class="[item.icon, { ac: item.value == now }]"></span>
			</div>
			<div class="title" :class="{ ac: item.value == now }">{{ item.name }}
			</div>
		</div>

	</div>
</template>

<script>
export default {
	name: "Tabbar",
	props: {
		now: String
	},
	watch: {
		last_msg: function () {
			this.total_msg = 0;
			this.$chat_msgs_lists.forEach(val => {

				this.total_msg += val.count;
			})
		}
	},
	data() {
		return {
			total_msg: 0,
			last_msg: {},
			list: [
				{
					name: this.$t("home"),
					icon: "homet",

					value: 1,
					path: "/home"
				},

				{
					name: this.$t("market"),
					icon: "market",

					value: 2,
					path: "/trde"
				},
				{
					name: "Msgs",
					icon: "wallet",

					value: 3,
					path: "/chat"
				},
				{
					name: "Find",
					icon: "find",

					value: 5,
					path: "/find"
				},
				{
					name: this.$t("wallet"),
					icon: "me",

					value: 4,
					path: "/profile"
				}
			]
		};
	},
	created() {
		this.get_msg();
		this.ws_chat.conn();
		this.ws_chat.Sub(this.ChatMsg, "tb_chat_list");

	},
	methods: {
		ChatMsg(data) {
			let user_id = data.form_avatar.user_id
			if (this.$chat_msgs_lists.has(user_id)) {
				let msg = this.$chat_msgs_lists.get(user_id)
				msg.count++
				msg.content = data.content
				msg.msg_id = data.msg_id;

				this.$chat_msgs_lists.set(user_id, msg)
			} else {
				this.$chat_msgs_lists.set(user_id, data)

			}
			this.last_msg = { time: new Date().getTime() }
			// this.msgs.push(data)

		},
		get_msg() {
			this.get_chat("chat_msg_list", { plat: this.plat, last_msg_id: this.last_msg_id, to: this.to }).then(res => {


				res.data.forEach(val => {
					if (!this.$chat_msgs_lists.has(val.form_avatar.user_id)) {
						this.$chat_msgs_lists.set(val.form_avatar.user_id, val)
					}

					this.last_msg = res.data[0];
				})
			})
		},
		handleChange(path) {
			let n = this.list.filter(item => item.value == this.now)[0].path;
			if (n == path) return;
			this.$router.push({ path });
		}
	}
};
</script>

<style scoped lang="scss">
.tabbar {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background: #fff;
	z-index: 9;
	border-top: 1px solid #f2f2f2;
	padding-bottom: 0.1rem;

	.icon {
		text-align: center;
		position: relative;

		.unred-msg {
			position: absolute;
			left: 3rem;
			line-height: 100%;
			background: #f13202;
			padding: 0.2rem 1rem;

			font-size: 1.3rem;
			color: #fff;
			border-radius: 2rem;
		}

		span {
			display: block;
			width: 100%;
			height: 3.5rem;
		}

		.homet {


			background: url("../assets/bit/icon/home.png") center center no-repeat;
			background-size: 2rem;

			&.ac {
				background: url("../assets/bit/icon/home_ac.png") center center no-repeat;
				background-size: 2rem;
			}
		}

		.home {


			background: url("../assets/bit/icon/home.png") center center no-repeat;
			background-size: 2rem;

			&.ac {
				background: url("../assets/bit/icon/home_ac.png") center center no-repeat;
				background-size: 2rem;
			}
		}

		.find {


			background: url("../assets/bit/icon/find.png") center center no-repeat;
			background-size: 2rem;

			&.ac {
				background: url("../assets/bit/icon/find_hover.png") center center no-repeat;
				background-size: 2rem;
			}
		}

		.market {


			background: url("../assets/bit/icon/market.png") center center no-repeat;
			background-size: 2rem;

			&.ac {
				background: url("../assets/bit/icon/market_ac.png") center center no-repeat;
				background-size: 2rem;
			}
		}

		.wallet {


			background: url("../assets/bit/icon/wallet.png") center center no-repeat;
			background-size: 2rem;

			&.ac {
				background: url("../assets/bit/icon/wallet_ac.png") center center no-repeat;
				background-size: 2rem;
			}
		}


		.me {


			background: url("../assets/bit/icon/me.png") center center no-repeat;
			background-size: 1.3rem;

			&.ac {
				background: url("../assets/bit/icon/me_ac.png") center center no-repeat;
				background-size: 2rem;
			}
		}
	}

	.title {
		color: #97a2b6;

		&.ac {
			color: #111729;
		}
	}
}

.tabbar-item {
	display: inline-block;
	width: 20%;
	text-align: center;



}






.tabbar-list {
	position: relative;
	z-index: 1;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;

	padding: 0.1rem 2rem;
}
</style>
