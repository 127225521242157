<template>
    <div>
        <nav-bar>Notifications</nav-bar>
        <div class="notify">
            <div class="nav">

                <li class="ac">Announcement</li>
            </div>

            <div class="list">
                <div v-for="(item, index) in data" :key="index" class="item">
                    <router-link :to="{
                        path: '/announcement', query: {
                            id: item.id
                        }
                    }">
                        <div class="title">{{ item.title }}</div>
                        <div class="content">{{ item.summary }}</div>
                        <div class="time">{{ item.created_at }}</div>
                    </router-link>

                </div>

            </div>
        </div>



    </div>
</template>

<script>
import AnimatedNumber from "animated-number-vue";

export default {
    components: { AnimatedNumber },
    name: "AccountHis",
    data() {
        return {
            loading: false,
            data: ""


        };
    },
    created() {

        this.list();



    },



    methods: {

        list() {
            this.get("notice/list").then(res => {
                this.data = res.data

            })
        }


    }
};
</script>

<style lang="scss" scoped>
.notify {
    background: #fff;
    min-height: 100vh;

    .list {
        .item {
            background: url("../../assets/bit/icon/announcement.png") no-repeat 1rem 2rem;
            background-size: 3rem;
            display: block;
            padding: 2rem;
            padding-left: 5rem;

            div {
                padding: 0.3rem 0;

                display: block;

                &.title {
                    color: #111729;

                }

                &.content {

                    height: 4rem;

                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box; //作为弹性伸缩盒子模型显示。
                    -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
                    -webkit-line-clamp: 2; //显示的行
                    color: #c7c8cd;

                }

                &.time {
                    padding-top: 1rem;
                    color: #dcdcdd;
                }
            }
        }
    }

    .nav {
        background: #fff;
        padding: 0.5rem 2rem;
        display: flex;
        flex-wrap: wrap;

        justify-content: space-between;

        li {
            padding: 1rem 0;
            list-style: none;
            color: #97a2b6;

            &.ac {
                border-bottom: 3px solid #643beb;
                color: #111729;
            }
        }
    }
}
</style>
