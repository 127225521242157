<template>
	<div>
		<nav-bar>{{ $t('payout') }}</nav-bar>
		<div class="tab">
			<li :class="{ ac: tp == 0 ? true : false }" @click="balance_payout">{{ $t('balance') }}</li>
			<li :class="{ ac: tp == 1 ? true : false }" @click="sarlry_payout">{{ $t('sarlry') }}</li>
		</div>

		<div class="bank" v-if="bindbank == false">
			<tip>{{ $t('not_bind_bank') }}</tip>
			<router-link to="/bind_card"><base-btn>{{ $t('bind_bank') }}d</base-btn></router-link>
		</div>

		<div class="safe" v-if="bindbank == true">
			<div class="balance">
				<i>{{ $t('active_balance') }}:</i>
				<i>
					<animated-number class="funds" :value="balance" :formatValue="formatToPrice" :duration="300" />
				</i>
			</div>
			<base-form ref="from">
				<div>
					<base-input v-model="amount" maxlength="20" :roule="{ reg: '^[0-9]{3,12}$', msg: 'Invild Amount' }"
						placeholder="Amount"></base-input>
				</div>
			</base-form>
			<div>
				<i>{{ $t('taxs') }}:</i>
				<i>
					<animated-number class="funds" :value="fee" :formatValue="formatToPrice" :duration="300" />
				</i>
			</div>
			<div>
				<i>{{ $t('total_amount') }}:</i>
				<i>
					<animated-number class="funds" :value="total" :formatValue="formatToPrice" :duration="300" />
				</i>
			</div>

			<div class="bankitem">
				<van-radio-group v-for="(item, index) in bank" :key="index" v-model="bank_id">
					<van-radio :name="item.id">{{ item.bank_name }} {{ item.bank_account }}</van-radio>
				</van-radio-group>
			</div>
			<tip wrapable :scrollable="false" color="#1989fa" background="#ecf9ff" left-icon="info-o">
				{{ $t('payout_note') }}
			</tip>

			<div>
				<base-btn :loading="loading" @click="addBank">{{ $t('submit') }}</base-btn>
			</div>
		</div>
	</div>
</template>

<script>
import AnimatedNumber from "animated-number-vue";
import { Dialog } from "vant";
export default {
	components: { AnimatedNumber },
	name: "AccountHis",
	data() {
		return {
			feeset: 0,
			loading: false,
			current: 0,
			amount: "",
			fee: 0,
			total: 0,
			balance: 0,
			bank: [],
			bank_id: 0,
			info: "",
			dowlone: "",
			kyc: false,
			bindbank: false,
			tp: 0,
			ba: "",

			gz: "",
		};
	},
	created() {
		this.getInfo();

		this.getBank();
		this.getFee();
	},
	activated() {
		this.getInfo();

		this.getBank();
	},
	watch: {
		amount(newvol) {
			this.fee = (newvol * this.feeset) / 100;
			this.total = parseFloat(this.fee) + parseFloat(newvol);
		}
	},
	mounted() { },
	methods: {
		getFee() {
			this.get("sys/config", { key: "payout_fee" }).then(res => {
				this.feeset = res.data.payout_fee;
			});
		},
		getBank() {
			this.get("bank/list").then(res => {

				if (res.data.length == 0) {
					this.bindbank = false;

					return;
				}
				this.bindbank = true;
				this.bank = res.data;
			});
		},
		balance_payout() {
			this.balance = this.ba;
			this.tp = 0
		},
		sarlry_payout() {
			this.balance = this.gz;
			this.tp = 1
		},

		getInfo() {
			this.get("user/info").then(res => {
				if (res.data.kyc_status == 2) {
					//wthis.kyc = true;
				}
				this.balance = res.data.balance;
				this.gz = res.data.salary;
				this.ba = res.data.balance;
			});
		},
		formatToPrice(value) {
			return `₹${Number(value).toFixed(2)}`;
		},
		addBank() {
			//console.log(this.bank_id);
			if (this.$refs.from.submit() == false) {
				return;
			}
			let patm = { amount: this.amount, bank_id: this.bank_id, tp: this.tp };
			this.loading = true;
			this.post("payout/create", patm).then(res => {
				this.$toast(res.info);
				this.loading = false;
			});
		},

	}
};
</script>

<style lang="scss" scoped>
.tab {
	padding: 2rem;
	padding-top: 2rem;
	display: block;
	background: #fff;
	display: flex;
	flex-wrap: wrap;
	font-size: 1.2rem;
	justify-content: flex-start;

	li {
		text-align: center;
		border-radius: 0.8rem;
		list-style: none;
		padding: 1.5rem 0;
		background: #e5e2f1;

		width: 45%;
		margin-left: 1rem;

		&.ac {
			background: #5f3ee2;
			color: #fff;
		}
	}
}

.kyc,
.bank {
	background: #fff;
	padding: 2rem;

}

.safe {
	padding: 1rem;
	height: 90vh;
	background: #fff;

	.funds {
		font-size: 2.5rem;
		color: #555555;
		font-weight: normal;
	}

	.balance {
		display: block;
		border-bottom: 1px solid #f2f2f2;
		padding: 2rem 0;
	}

	.bankitem {
		display: block;
		border: 1px dashed #555555;
		border-radius: 1rem;
		padding: 2rem 0;

		&::before {
			display: block;
			content: "SELECT BANK";
			padding-left: 2rem;
		}
	}

	div {
		i {
			font-style: normal;

			&:first-child {
				color: #949292;
			}

			&:last-child {
				font-weight: bold;
				font-size: 3rem;
			}
		}

		margin: 1rem;
	}
}
</style>