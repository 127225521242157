<template>
	<div id="app">
		<keep-alive>
			<router-view v-if="$route.meta.keepAlive"></router-view>
		</keep-alive>
		<router-view v-if="!$route.meta.keepAlive"></router-view>
	</div>
</template>

<script>
	import Tabbar from "./components/tabbar.vue";

	export default {
		name: "App",
		components: {
			Tabbar
		},
		data() {
			return {
				transitionName: ""
			};
		},
		watch: {
			//使用watch 监听$router的变化
			$route(to, from) {}
		}
	};
</script>

<style>
body {
	margin: 0;
	background: #f6f7f9;
}
* {
	color: #06275c;
	font-size: 1.625rem;
}
.has-header {
	padding-top: 5rem;
}
.has-tabbar {
	padding-bottom: 10.1875rem;
}
.vux-pop-out-enter-active,
.vux-pop-out-leave-active,
.vux-pop-in-enter-active,
.vux-pop-in-leave-active {
	will-change: transform;
	transition: all 500ms;
	height: 100%;
}
.vux-pop-out-enter {
	opacity: 0;
	transform: translate3d(-100%, 0, 0);
}
.vux-pop-out-leave-active {
	opacity: 0;
	transform: translate3d(100%, 0, 0);
}
.vux-pop-in-enter {
	opacity: 0;
	transform: translate3d(100%, 0, 0);
}
.vux-pop-in-leave-active {
	opacity: 0;
	transform: translate3d(-100%, 0, 0);
}
</style>
