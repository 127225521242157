<template>
	<div>
		<van-nav-bar
			title="Withdraw"
			right-text="Records"
			@click-right="onClickRight"
			left-arrow
			@click-left="onClickLeft"
		/>
		<div class="safe">
			<van-uploader :after-read="afterRead" />
		</div>
	</div>
</template>

<script>
	import AnimatedNumber from "animated-number-vue";
	export default {
		components: { AnimatedNumber },
		name: "AccountHis",
		data() {
			return {
				loading: false,
				current: 0,
				amount: "",
				fee: 0,
				total: 0,
				balance: 0,
				bank: [],
				bank_id: 0
			};
		},
		created() {
			this.getInfo();
			this.getBank();
		},
		watch: {
			amount(newvol) {
				this.fee = newvol * 0.18;
				this.total = parseFloat(this.fee) + parseFloat(newvol);
			}
		},
		mounted() {},
		methods: {
			getBank() {
				this.get("bank/list").then(res => {
					this.bank = res.data;
				});
			},
			getInfo() {
				this.get("user/info").then(res => {
					this.balance = res.data.balance;
				});
			},
			formatToPrice(value) {
				return `₹${Number(value).toFixed(2)}`;
			},
			addBank() {
				console.log(this.bank_id);
				if (this.$refs.from.submit() == false) {
					return;
				}
				this.loading = true;
				this.post("payout/create", this.from).then(res => {
					this.$toast(res.info);
					this.loading = false;
				});
			},
			onClickRight() {
				this.$router.push("./hist");
			},
			onClickLeft() {
				this.$router.go(-1);
			}
		}
	};
</script>

<style lang="scss" scoped>
.safe {
	padding: 1rem;
	height: 90vh;
	background: #fff;
	.funds {
		font-size: 2.5rem;
	}
	div {
		i {
			font-style: normal;
			&:first-child {
				color: #949292;
			}
			&:last-child {
				font-weight: bold;
				font-size: 3rem;
			}
		}
		margin: 1rem;
	}
}
</style>