export default {
    initChatBox() {
        let av = JSON.parse(localStorage.getItem("chat_box"))


        this.Nick = av.nick_name

        this.msg.receiver = av.user_id;
        this.ws_chat.conn();
        this.ws_chat.Sub(this.ChatMsg, "chat_box");
        this.get_msg();
        this.redmsg();
    },
    setUrl(data) {
        if (data.content.startsWith("data")) {
            return data.content
        } else {
            return this.chat_host + "/" + data.url;
        }
    },
    getFile(event) {
        var file = event.target.files[0];
        let name = file.name
        let num = name.split('.')
        let mum = num[num.length - 1]
        let arr = ['jpg', 'jpeg', 'gif', 'png', 'JPG', 'JPEG', 'GIF', 'PNG']
        let msgtype = ''
        if (arr.indexOf(mum) == -1) {
            msgtype = "file"
        } else {
            msgtype = "img"
        }



        console.log(file.name, mum, msgtype)


        let reader = new FileReader()
        reader.readAsDataURL(file);
        reader.onload = event => {
            let content = event.target.result
            if (msgtype == "file") {
                content = name;
            }
            console.log(event)
            let tmp = {
                content: content,
                ref: 1,
                type: msgtype,
                send_time: 0,


            }
            this.msgs.push(tmp)
            var formData = new FormData();
            formData.append("file", file, name)
            formData.append("content", name)
            formData.append("receiver", this.msg.receiver)
            formData.append("plat", this.plat)
            this.upload("/upload", formData).then(res => {
                console.log(res)
            })
            //console.log(event.target.result)

        }

    },


    formatTime(time) {
        var now = new Date()


        var date = new Date(time * 1000);  // 参数需要毫秒数，所以这里将秒数乘于 1000
        var Y = date.getFullYear();
        var M = (date.getMonth() + 1 < 10 ? (date.getMonth() + 1) : date.getMonth() + 1);
        var D = date.getDate();
        var h = date.getHours();
        var m = date.getMinutes();

        if (D == now.getDate() && M == now.getMonth() + 1 && Y == now.getFullYear()) {
            return h + ":" + m
        } else {
            return Y + "/" + M + "/" + D
        }

    },
    scrootobtn() {
        this.$nextTick(() => {
            let box = this.$el.querySelector(".msg-contents")
            box.scrollTop = box.scrollHeight;

        })
    },
    ChatMsg(data) {

        this.msgs.push(data)
        this.redmsg();
        setTimeout(() => {
            this.scrootobtn();

        }, 100);

    },
    get_msg() {
        this.get_chat("list", { plat: this.plat, last_msg_id: this.last_msg_id, receiver: this.msg.receiver }).then(res => {
            this.msgs = res.data;
            setTimeout(() => {
                this.scrootobtn();

            }, 100);

        })
    },
    redmsg() {
        this.get_chat("read", { plat: this.plat, acc_id: this.msg.receiver }).then(res => {


        })
    },
    sendmsg() {
        if (this.sending == 0) {
            return
        }
        if (this.msg.content.length == 0) {
            return;
        }
        this.sending = 0
        this.post_chat("send", this.msg).then(res => {
            if (res.status == 1) {
                let tmp = {
                    content: this.msg.content,
                    ref: 1,
                    send_time: res.data.tm,
                    type: "text"

                }

                this.msgs.push(tmp)

                this.sending = 1
                let user_id = this.msg.receiver

                let msg = this.$chat_msgs_lists.get(user_id)
                msg.content = this.msg.content;
                msg.msg_id = res.data.msg_id;
                msg.send_time = res.data.tm;
                this.$chat_msgs_lists.set(user_id, msg)
                this.msg.content = ""

            }

            return
            // this.get_msg()
        })
    },
    showimg(data) {

        this.show_img_url = data;
        this.reader_show = true;
    }
}