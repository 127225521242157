<template>
    <div class="main">
        <nav-bar>BIT Pro</nav-bar>
        <div class="header">


            <div class="btns">
                <div class="total">
                    <div class="items">
                        <li>{{ formatToPrice(total.amount) }}</li>
                        <li>Total Investment</li>
                    </div>
                    <div class="items">
                        <li>{{ formatToPrice(total.profit) }}</li>
                        <li>Total Profits</li>
                    </div>
                </div>
                <base-btn @click="buy">BUY</base-btn>


            </div>

        </div>
        <div class="title">My BIT Pro Investment</div>

        <div class="list">
            <div class="item" @click="detail(item.id)" v-for="(item, index) in data" :key="index">
                <div class="col">
                    <li>{{ item.amount }}</li>
                    <li>Principal Amount</li>
                </div>
                <div class="col">
                    <li>{{ item.profit }}</li>
                    <li>Total profit</li>
                </div>
                <div class="col">
                    <li>{{ item.created_at }}</li>
                    <li>Buy time</li>
                </div>
                <div class="col">
                    <li :class="{ red: item.status == 0, green: item.status != 0 }">{{ item.status == 0 ? "Active" : "Stop"
                    }}
                    </li>
                    <li>Status</li>
                </div>


            </div>

        </div>
        <van-popup class="td" :overlay="true" v-model="trade" position="bottom" :style="{ height: '50%', width: '100%' }">
            <div class="trade">
                <div class="title">Buy in BIT pro</div>
                <div>


                    <base-input v-model="amount" placeholder="amount"
                        :roule="{ reg: '^[0-9]{3,12}$', msg: 'Invild Amount' }"></base-input>
                </div>
                <div class="day">
                    <div>3Days</div>
                    <div class="ac">30Days</div>
                </div>
                <div class="product">
                    <div>
                        <li>Balance:</li>
                        <li>{{ formatToPrice(balance) }}</li>
                    </div>

                </div>
                <div class="amount">

                    <li @click="amount = 6000">6000</li>
                    <li @click="amount = 10000">10000</li>
                    <li @click="amount = 50000">50000</li>
                    <li @click="amount = 100000">100000</li>
                    <li @click="amount = 200000">200000</li>
                </div>
                <base-btn @click="invistment" :loading="loading">Confirm</base-btn>
            </div>
        </van-popup>

        <Tabbar :now="'3'" />
    </div>
</template>

<script>
import Tabbar from "../components/tabbar.vue";
import Clipboard from "clipboard";
import TopHeader from "./header.vue";
import { Dialog } from "vant";
import AnimatedNumber from "animated-number-vue";

export default {
    name: "Profile",
    components: {
        Tabbar,
        AnimatedNumber,
        TopHeader
    },
    created() {

    },
    data() {
        return {
            trade: false,
            balance: 0,
            amount: "",
            loading: false,
            data: [],
            total: ""

        };
    },
    activated() {


    },
    created() {
        this.lists();
        this.totals();

    },

    methods: {
        detail(id) {
            this.$router.push({ path: "/pro_detail", query: { id: id } })

        },
        totals() {
            this.get("pro/total").then(res => {
                this.total = res.data;
            })
        },
        invistment() {
            let $this = this
            this.loading = true;
            this.post("pro/create", { amount: this.amount }).then(res => {
                Dialog.alert({
                    message: res.info
                }).then(() => {
                    $this.getInfo();
                    $this.loading = false;
                    this.lists();
                    this.totals();
                });

            })
        },
        buy() {
            this.trade = true
            this.getInfo();
        },
        getInfo() {
            this.get("user/info").then(res => {

                this.balance = res.data.balance;

            });
        },
        lists() {
            this.get("pro/list").then(res => {

                this.data = res.data;

            });
        },

        formatToPrice(value) {
            if (this.eyes === false) {
                return "₹******";
            }
            return `₹${Number(value).toFixed(2)}`;
        },

    }
};
</script>

<style  lang="scss" scoped>
.day {
    padding: 1rem;
    display: flex;
    justify-content: start;

    div {

        background: #c7c7e0;
        padding: 1rem 5rem;
        color: #47425b;

        &:first-child {
            border-radius: 1rem 0 0 1rem;
            border-right: 0.5rem solid #5f3ee2;
        }

        &:last-child {
            border-radius: 0rem 1rem 1rem 0;
        }

        &.ac {
            background: #5f3ee2;
            font-weight: bold;
            color: #fff;
        }
    }
}

.main {
    height: 100vh;
    overflow: hidden;

    .title {
        padding: 2rem;
        color: #000;
    }

    .total {
        padding: 2rem;
        display: flex;
        justify-content: space-between;

        .items {


            li {
                line-height: normal;
                display: block;
                padding: 0.2rem 0;

                &:first-child {
                    color: #191919;
                    font-size: 2rem;
                }

                &:last-child {
                    color: #aeaeae;
                }
            }
        }
    }

    .list {
        height: 60vh;
        overflow-y: scroll;

        .item {
            border-radius: 1rem;
            margin: 1rem;
            background: #fff;

            background-size: 2.5rem;
            padding: 1rem;
            padding-right: 3rem;

            display: flex;
            justify-content: space-between;

            .col {
                li {
                    list-style: none;
                    display: block;
                    padding: 1rem 0;


                    &:first-child {
                        color: #000;

                        &.red {
                            color: #4aa89e;
                        }

                        &.green {
                            color: #f30404;

                        }
                    }

                    &:last-child {
                        color: #9a9e9d;
                    }
                }
            }

        }
    }

    .btns {

        padding: 2rem;

        .note {
            color: #fff;
            font-size: 2rem;
            padding: 1rem;
        }

        .transfer_in {
            text-align: center;
            background: #4aa89e;
            width: 100%;
            display: block;
            border-radius: 1rem;
            line-height: 5rem;
            height: 5rem;
            color: #fff;
        }
    }

    .header {

        display: block;
        background: #fff;




        h1 {

            border-bottom: 1px solid #e9e8e7;
            font-weight: normal;
            color: #070707;
            text-align: center;

            margin: 0;
            padding: 1rem 2rem;
            font-size: 2.5rem;
        }



    }
}

.trade {
    padding: 2rem;

    .title {
        color: #101729;
    }

    .product {
        display: flex;
        flex-wrap: wrap;

        justify-content: start;
        padding: 2rem 0;

        li {
            display: inline-block;
            list-style: none;

            &:first-child {
                color: #97a3b6;
            }

            &:last-child {
                padding-left: 1rem;
                color: #5f3ee2;
            }

        }
    }

    .amount {
        display: flex;
        justify-content: space-between;

        li {
            padding: 1rem 1.2rem;
            border: 1px solid #e9e8e7;
            list-style: none;
            border-radius: 0.7rem;
        }
    }



}
</style>
