import axios from 'axios';
import router from "../../routers";
const service = axios.create({
    // process.env.NODE_ENV === 'development' 来判断是否开发环境
    // easy-mock服务挂了，暂时不使用了
    baseURL: 'https://lof.lofchat.com/api/',
    // baseURL: 'https://dolquant.com/api/',
    // baseURL: 'https://suiquant.com/api/',
    timeout: 5000
});
axios.defaults.headers = { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8', 'lang': "en" };
service.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        console.log(error);
        return Promise.reject();
    }
);

service.interceptors.response.use(
    response => {
        if (response.status === 200) {
            if (response.data.status == 3) {
                try {
                    const val = localStorage.getItem('isFirstLogin');
                    localStorage.clear();
                    localStorage.setItem('isFirstLogin', val)
                } catch (error) {
                    console.log(error)
                }
                router.push({ path: "/login" });
                return
            }
            return response.data;
        } else {
            Promise.reject();
        }
    },
    error => {
        console.log(error);
        return Promise.reject();
    }
);

export default service;