<template>
	<div class="home has-tabbar">
		<div class="focus-img">
			<swiper :options="swiperOption" ref="mySwiper">
				<swiper-slide v-for="(item,index) in imgs" :key="index">
					<img :src="host+'/'+item.img" @click="goto(item)" />
				</swiper-slide>

				<div class="swiper-pagination" slot="pagination"></div>
			</swiper>
		</div>
		<div class="user-info">
			<li>Dear</li>
			<li>{{info.name}},Welcome!</li>
		</div>
		<div class="invite-code">
			<li>Invite Code:</li>
			<li class="code">{{info.code}}</li>
		</div>
		<div class="info-group">
			<div class="item">
				<li>1</li>
				<li>Registed Friends</li>
			</div>
			<div class="item">
				<li>500</li>
				<li>Register Bonus</li>
			</div>
			<div class="item">
				<li>1</li>
				<li>Recharged Friends</li>
			</div>
			<div class="item">
				<li>5000.00</li>
				<li>Recharged bonus</li>
			</div>
			<div class="item">
				<li>1</li>
				<li>Wa verfird Friends</li>
			</div>
			<div class="item">
				<li>{{info.rate}}%</li>
				<li>Trade ratio</li>
			</div>
			<div class="tip">
				<li>TIP:</li>
				<li>Register bonus=Wa verified Friends Total*100</li>
			</div>
		</div>
		<div class="login-btn">INVITE FRIENDS NOW</div>
		<div class="transfer">
			<div class="btn">Transfer register bonus to balance</div>
			<div class="btn">Transfer recharge bonus to balance</div>
		</div>

		<Tabbar :now="'1'" />
	</div>
</template>

<script>
	import Tabbar from "../components/tabbar.vue";
	import Header from "./header.vue";
	import { swiper, swiperSlide } from "vue-awesome-swiper";
	import "vue-awesome-swiper/node_modules/swiper/dist/css/swiper.css";

	export default {
		name: "Home",
		components: {
			Tabbar,
			Header,
			swiper,
			swiperSlide
		},
		data() {
			return {
				swiperOption: {
					loop: true,
					spaceBetween: 0,
					observer: true, //修改swiper自己或子元素时，自动初始化swiper
					observeParents: false,
					paginationClickable: true,
					autoplay: {
						delay: 2000,
						stopOnLastSlide: false,
						disableOnInteraction: false
					},
					// 显示分页
					pagination: {
						el: ".swiper-pagination",
						clickable: true //允许分页点击跳转
					}
				},
				imgs: [],

				info: ""
			};
		},
		activated() {
			this.getFocusImg();

			this.getInfo();
		},
		created() {
			this.getFocusImg();

			this.getInfo();
		},
		activated() {
			//this.ws.Sub(this.marketData, "home");
		},
		methods: {
			getInfo() {
				this.get("user/info").then(res => {
					this.info = res.data;
				});
			},

			getFocusImg() {
				this.get("focus/list").then(res => {
					this.imgs = res.data;
				});
			}
		},

		mounted() {}
	};
</script>
<style lang="scss" scoped>
.home {
	//background: #ffffff;

	min-height: 100vh;
	.focus-img {
		background: #000;
		box-sizing: border-box;
		height: 20rem;
		margin: 0rem;
		img {
			height: 20rem;
		}
	}
	.user-info {
		padding: 1rem 2rem;
		background: #fff;
		li {
			display: inline-block;
			&:first-child {
				color: #a3a2a1;
			}
			&:last-child {
				padding-left: 1rem;
			}
		}
	}
	.login-btn {
		background-image: linear-gradient(90deg, #5ad2fe, #1c9ced);
		display: block;
		padding: 1.8rem;
		text-align: center;
		border-radius: 6rem;
		color: #fff;
		margin-top: 3rem;
		margin-bottom: 8rem;
		font-size: 2.5rem;
		box-shadow: 10px 10px 5px #f2f2f2;
		font-weight: bold;
		margin: 1rem 1rem;
	}
	.invite-code {
		padding: 0rem 2rem;
		background: #fff;
		li {
			display: inline-block;
			&:first-child {
				color: #a3a2a1;
			}
			&.code {
				color: rgba(11, 45, 155, 0.582);
				font-size: 2rem;
				font-weight: bold;
			}
			&:last-child {
				padding-left: 1rem;
			}
		}
		padding-bottom: 2rem;
	}
	.info-group {
		overflow: hidden;
		margin-top: 1rem;
		background: #fff;
		padding: 1rem;
		.item {
			padding-top: 1rem;
			float: left;
			width: 50%;
			li {
				list-style: none;
				display: block;

				&:last-child {
					color: #a3a2a1;
				}
				&:first-child {
					color: #000;
					font-weight: bold;
					font-size: 2rem;
				}
			}
		}
		.tip {
			clear: both;

			display: block;
			border: 1px dashed burlywood;
			margin: 1rem;
			border-radius: 1rem;
			padding: 1rem 2rem;
			margin-top: 2rem;

			li {
				list-style: none;
				&:first-child {
					color: burlywood;
				}
				&:last-child {
					color: burlywood;
				}
			}
		}
	}
	.transfer {
		padding: 2rem 0;
		background: #fff;
		.btn {
			margin-left: 1rem;
			width: 40%;
			background: #1c9ced;

			display: inline-block;
			padding: 1.2rem;
			text-align: center;

			color: #fff;

			font-size: 1.5rem;
			border-radius: 5rem;

			font-weight: bold;
		}
	}
}
</style>
