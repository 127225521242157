module.exports = {
    welcome: "Welcome to back",
    siginaccount: "Sign into your account",
    mob: "Mobile phone number",
    pass: "Password",
    forgot_pass: "Forget Password",
    login: "Sign in",
    nothaveacccount: "Don't have an account",
    reg: "Sign Up",
    create_account: "Create an account",
    get_free_account: "Let's get started with a free quant trading account",
    email: "Email",
    name: "Full Name",
    ref_code: "Ref code",
    continue: "Continue",
    have_account: "Already have an account",
    submit: "Submit",
    otp: "OTP",
    resend: "Resend",
    deposit: "Deposit",
    payout: "Withdraw",
    team: "Team",
    service: "Service",
    total_asstes: "Total Asset Value",
    home: "Home",
    market: 'Market',
    rewards: "Rewards",
    wallet: "Me",
    serch: "Search",
    invite_code: "Ref code",
    copy_share: "Copy link and share",
    reward_list: "Rewards Record",
    share_words: "When the invited user recharges, the reward will be automatically added to your account balance",
    balance: "Balance",
    sarlry: "Salary Balance",
    verfed: "Verified",
    notverfd: "Not Verified",
    fundlog: "Account Settlement",
    sarlry_log: "Payroll Records",
    fund_log: "Financial records",
    invite_log: "Invitation reward record ",
    hist: "Transaction History",
    idvef: "Identify Verification",
    acc_safe: "Account security",
    sigin_out: "Sign out",
    recommand_amount: "Recommended amount",
    active_balance: "Available Balance",
    taxs: "Taxs",
    total_amount: "Total Amount",
    payout_note: "Withdrawal charges 20% Taxs, withdraw time Monday-Friday 7am-9am,2,Salary Withdrawal time Monday-Sunday 7am-9am ",
    need_ver: "You need to complete the  Identify Verification  first",
    verf: "Identify Verification",
    not_bind_bank: "You have not bound the cash withdrawal account yet, please bind it first",
    bind_bank: "Bind",
    time: "Time",
    create: "Create",
    pending: "Pending in bank",
    refund: "Refund",
    success: "Success",
    unpaid: "Unpaid",
    order_num: "Order Ref",
    trading: "Trading Pairs",
    traded: "History",
    posotion: "Position",
    status: "Status",
    js: "Settlement",
    product: "Product",
    path: "Path",
    open: "Opening Price",
    close: "Close Price",
    open2: "Open Price",
    close: "Close Price",
    pathhouer: "24H",
    market_detail: "Market details",
    vol: "Vol",
    low: "Low",
    heigh: "High",
    call: "CALL",
    put: "PUT",
    rate: "Signal Rate",
    notice: "Notice",
    level: "Level",
    reg_num: "Total number of registered",
    recharge_num: "Total number of rechargers",
    recharge_amount: "Total recharge amount",
    payout_amount: "Total withdrawal amount",
    lang: "Language"









}