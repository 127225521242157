<template>
	<div class="AccountHis">
		<van-nav-bar :title="title" left-arrow @click-left="onClickLeft" />
		<div class="pageinfo" v-html="content"></div>
	</div>
</template>

<script>
export default {
	name: "AccountHis",
	data() {
		return {
			content: "loading",
			title: ""
		};
	},
	created() {
		this.getInfo();
	},

	methods: {
		getInfo() {
			this.get("page/info", { id: this.$route.query.id }).then(res => {
				this.content = res.data.contents;
				this.title = res.data.title;
			});
		},
		onClickLeft() {
			this.$router.go(-1);
		}
	}
};
</script>

<style lang="scss" scoped>
.pageinfo {
	padding: 1rem;
	background: #fff;
	height: 90vh;
	color: #000;
	overflow-y: scroll;
}
</style>