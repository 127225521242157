<template>
    <div>
        <nav-bar>Pro Details</nav-bar>
        <div class="detail">
            <div class="warp">


                <div class="item">
                    <li>Amount</li>
                    <li>{{ data.amount }}</li>
                </div>
                <div class="item">
                    <li>Profit</li>
                    <li class="red">{{ data.profit }}</li>
                </div>
                <div class="item">
                    <li>Status</li>
                    <li :class="{ red: data.status == 0, green: data.status != 0 }">{{ data.status == 0 ? "Active" : "Stop"
                    }}</li>
                </div>
                <div class="item">
                    <li>Time</li>
                    <li>{{ data.created_at }}</li>
                </div>

            </div>

        </div>
        <div class="btns">

            <li class="out" @click="trade = true">Transfer Principal</li>
            <li @click="transer_profit">Transfer Profit</li>
        </div>
        <div class="title">Revenue record</div>
        <div class="list">
            <div class="item">
                <div class="col">
                    <li>5000</li>
                    <li>Amount</li>
                </div>


                <div class="col">
                    <li>2023/10/02 20:00:12</li>
                    <li>Date Time</li>
                </div>


            </div>

        </div>
        <van-popup class="td" :overlay="true" v-model="trade" position="bottom" :style="{ height: '50%', width: '100%' }">

            <div class="noti">
                <div class="content">
                    If the principal is transferred out before the Pro product expires, a certain amount of penalty will be
                    deducted
                </div>
                <base-btn @click="transer_amount" :loading="loading">Confirm Transfer</base-btn>
            </div>

        </van-popup>
    </div>
</template>

<script>
import AnimatedNumber from "animated-number-vue";
import { Dialog } from "vant";

export default {
    components: { AnimatedNumber },
    name: "AccountHis",
    data() {
        return {
            id: "",
            data: "",
            trade: false,
        };
    },
    created() {

        this.id = this.$route.query.id;
        this.getinfo();




    },



    methods: {
        transer_amount() {
            this.post("pro/transfer", { id: this.id }).then(res => {
                Dialog.alert({
                    message: res.info
                }).then(() => {
                    this.getInfo();
                    this.trade = false

                });
            })

        },
        transer_profit() {
            let $this = this
            this.post("pro/transfer_amount", { id: this.id }).then(res => {
                Dialog.alert({
                    message: res.info
                }).then(() => {
                    $this.getInfo();

                });
            })

        },

        getinfo() {
            this.get("pro/info", { id: this.id }).then(res => {
                this.data = res.data;
            })


        },
        onClickLeft() {
            this.$router.go(-1);
        },



    }
};
</script>

<style lang="scss" scoped>
$call: #28baa7;
$put: #e44e69;

.noti {
    padding: 2rem;

    .content {
        border: 1px solid #e9e8e7;
        font-size: 2rem;
        color: #f40303;
        padding: 2rem;
        border-radius: 2rem;
    }
}

.title {
    padding: 2rem;
    color: #000;
}

.list {
    display: none;

    .item {
        border-radius: 1rem;
        margin: 1rem;
        background: #fff;

        background-size: 2.5rem;
        padding: 1rem;
        padding-right: 3rem;

        display: flex;
        justify-content: space-between;

        .col {
            li {
                list-style: none;
                display: block;
                padding: 1rem 0;

                &:first-child {
                    color: #000;
                }

                &:last-child {
                    color: #9a9e9d;
                }
            }
        }

    }
}

.btns {
    padding: 2rem;
    background: #fff;
    display: flex;
    justify-content: space-around;

    li {
        text-align: center;
        padding: 2rem 3rem;
        background: #5a3fda;
        color: #fff;
        border-radius: 5rem;
        list-style: none;

        &.out {
            background: #f40303;
        }


    }
}

.detail {
    background: #fff;
    padding: 2rem;

    .item {
        padding: 1rem 0;
        display: flex;
        justify-content: space-between;


        li {
            display: inline-block;
            vertical-align: middle;

            img {
                border-radius: 3rem;
                margin-right: 1rem;
                vertical-align: middle;
                width: 3rem;
                height: 3rem;
            }

            list-style: none;

            &:first-child {
                color: #99a2b4;
                font-size: 1rem;
            }

            &:last-child {
                color: #293348;
                font-size: 2rem;
                font-weight: 400;

            }

            &.red {
                color: $call;
            }

            &.green {
                color: $put;
            }
        }
    }

    .warp {}
}
</style>