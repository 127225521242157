<template>
    <div class="tip">

        <slot></slot>

    </div>
</template>

<script>
export default {
    name: "def-input",
    data() {
        return {

        };
    },
    watch: {

    },


    methods: {


    }
};
</script>

<style lang="scss" scoped>
.tip {
    background: #fff url("../assets/bit/warning.png") no-repeat 1rem center;

    background-size: 4rem;
    flex-wrap: wrap;
    font-size: 1.8rem;
    justify-content: space-evenly;
    border: 1px dashed #f1c0c0;
    padding: 2rem 2rem;
    padding-left: 6rem;
    border-radius: 2rem;
    color: #ed333c;
    margin: 2rem !important;
    line-height: 180%;

}
</style>