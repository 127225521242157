import Vue from 'vue'
import VueRouter from 'vue-router'
import router from './routers'
import App from './App'
import Vant from 'vant'
import VueI18n from 'vue-i18n'
//import 'swiper/dist/css/swiper.min.css'
//import 'swiper/dist/js/swiper.min'
import 'vant/lib/index.css'
import './style.css'
import './assets/iconfont/iconfont.css'
/**CUSTOM */
import BaseInput from "@com/input"
import Navbar from "@com/nav_bar"
import Tip from "@com/tip"
import BaseForm from "@com/form"
import BaseBtn from "@com/btn"
import Kline from "@com/chart/kline"
import Kview from "@com/chart/kview"
import { post, get } from './api/index'
import Ws from './api/utils/websocket'
import { Locale } from 'vant';
import { get_chat, post_chat, upload } from './api/chat'
import WsChat from './api/utils/websocket_chat'

// 引入英文语言包
import enUS from 'vant/es/locale/lang/en-US';
Locale.use('en-US', enUS);
Vue.use(VueRouter)
Vue.use(Vant)
Vue.use(VueI18n)
Vue.component("base-input", BaseInput);
Vue.prototype.get = get;
Vue.prototype.host = "https://cfltraderss.net";
Vue.prototype.chat_host = "https://lof.lofchat.com";
Vue.prototype.ws = Ws;
Vue.prototype.post = post;
Vue.prototype.upload = upload;
Vue.prototype.get_chat = get_chat;
Vue.prototype.post_chat = post_chat;
Vue.prototype.ws_chat = WsChat;
Vue.prototype.$chat_msgs_lists = new Map();
Vue.prototype.plat = "CFLUSER";
Vue.component("base-form", BaseForm);
Vue.component("base-btn", BaseBtn);
Vue.component("nav-bar", Navbar);
Vue.component("tip", Tip);
Vue.component("kline", Kline);
Vue.component("kview", Kview);

Vue.config.devtools = true
//import zh from './lang/zh'
import en from './lang/en'
import hd from './lang/hd'
//import br from './lang/br'
const messages = {

  'en': en,
  'hd': hd,
  //'zh': zh,
  //'br': br,



}
let lang = navigator.language || navigator.userLanguage;
console.log(lang)
const i18n = new VueI18n({
  locale: lang,
  messages: messages,
})
Vue.prototype.i18n = i18n;
router.beforeEach((to, from, next) => {
  if (localStorage.getItem("token") == null && to.path !== '/login' && to.path !== '/download' && to.path !== '/signin' && to.path !== '/forgotpass') {


    next({ path: "/login" });


  } else {
    next();
  }
});

new Vue({
  el: '#app',
  router,
  i18n,
  render: h => h(App)
})
i18n.locale = localStorage.getItem("lang")