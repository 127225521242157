<template>
	<div class="change-pass">
		<van-nav-bar title="ID Verification" left-arrow @click-left="onClickLeft" />
		<div class="safe" v-if="info.id==0">
			<van-notice-bar
				wrapable
				:scrollable="false"
				color="#1989fa"
				background="#ecf9ff"
				left-icon="info-o"
			>
				Why verify your identity?
				<br />To increase deposit limits for local currencies
			</van-notice-bar>
			<base-form ref="repass">
				<div>
					<base-input
						v-model="form.pan"
						maxlength="20"
						:roule="{reg:'^[0-9,a-z,A-Z]{10}$',msg:'Invild PAN'}"
						placeholder="PAN"
					></base-input>
				</div>

				<div>
					Identity Information
					<base-input
						v-model="form.name"
						maxlength="20"
						:roule="{reg:'^(.*){5,30}$',msg:'Invild Full Name'}"
						placeholder="Full Name"
					></base-input>
				</div>
				<div>
					<base-input
						v-model="form.birth_date"
						maxlength="20"
						:roule="{reg:'^[0-9]{8}$',msg:'Invild Date of Birth'}"
						placeholder="Date of Birth(YYYYMMDD)"
					></base-input>
				</div>
				<div>
					Residential Address
					<base-input
						v-model="form.address"
						maxlength="20"
						:roule="{reg:'[0-9,a-z,A-Z]{3,30}',msg:'Invild Password'}"
						placeholder="Residential Address"
					></base-input>
				</div>
				<div>
					<base-input
						v-model="form.pin"
						maxlength="20"
						:roule="{reg:'^[0-9]{6}$',msg:'Invild PIN'}"
						placeholder="PIN"
					></base-input>
				</div>
				<div>
					<base-input
						v-model="form.city"
						maxlength="20"
						:roule="{reg:'^[0-9,a-z,A-Z]{3,30}',msg:'Invild City'}"
						placeholder="City"
					></base-input>
				</div>
			</base-form>

			<div>
				<base-btn @click="verfi" :loading="loading">Submit</base-btn>
			</div>
		</div>
		<div class="verif" v-if="info.id!=0">
			<ul>
				<li>ID Verification</li>
				<li>
					<i class="green">Verified</i>
				</li>
			</ul>
			<ul>
				<li>Name</li>
				<li>{{info.full_name}}</li>
			</ul>
			<ul>
				<li>PAN</li>
				<li>{{info.pan}}</li>
			</ul>
			<ul>
				<li>Birth Date</li>
				<li>{{info.birth_date}}</li>
			</ul>
			<ul>
				<li>PIN</li>
				<li>{{info.pin}}</li>
			</ul>
			<ul>
				<li>City</li>
				<li>{{info.city}}</li>
			</ul>
			<ul>
				<li>Address</li>
				<li>{{info.address}}</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		name: "AccountHis",
		data() {
			return {
				loading: false,
				radio: 1,
				form: {
					pan: "",
					pin: "",
					name: "",
					address: "",
					birth_date: "",
					city: ""
				},
				info: {}
			};
		},
		created() {
			this.getInfo();
		},

		methods: {
			getInfo() {
				this.get("/user/verification_info").then(res => {
					this.info = res.data;
				});
			},
			verfi() {
				if (this.$refs.repass.submit() == false) {
					return;
				}
				this.loading = true;
				this.post("user/verification", this.form).then(res => {
					this.$toast(res.info);
					this.loading = false;
				});
			},
			onClickLeft() {
				this.$router.go(-1);
			}
		}
	};
</script>

<style lang="scss" scoped>
.change-pass {
	background: #fff;
	height: 100vh;
}
.safe {
	div {
		margin: 1rem;
	}
}
.verif {
	background: #fff;
	ul {
		display: block;
		overflow: hidden;
		&:first-child {
			border-top: 1rem solid #edeff0;

			border-bottom: 1rem solid #edeff0;
			li {
				&:first-child {
					font-weight: bold;
					color: #000;
					float: left;
					display: block;
				}
			}
		}
		li {
			padding: 2rem 2rem;
			&:first-child {
				color: #b2b3b4;
				float: left;
				display: block;
			}
			&:last-child {
				float: right;
				display: block;
				font-weight: 700;
				color: #000;
			}
			i {
				position: relative;
				margin-right: 2rem;
				float: right;
				font-style: normal;
				font-weight: bold;
				&.green {
					color: #047df9;
					&:before {
						content: " ";
						background-size: contain !important;
						display: block;
						background: #fff url(../../assets/imgs/ok.png) no-repeat
							right;
						width: 2rem;
						height: 2rem;
						position: absolute;
						right: 6.5rem;
						top: 0rem;
						z-index: 1;
					}
				}
			}
		}
	}
}
</style>