var Socket = { ws }
const wsuri = "wss://bitdeepquants.net/api/ws";
const ws = {
    connction: new WebSocket(wsuri),
    connected: false
}

const subfun = {}
var onopen = function () {
    ws.connected = true
    console.log("connected!")
}
var onclose = function () {
    ws.connected = false;
    ws.connction = new WebSocket(wsuri);
    var timer = setInterval(() => {
        if (ws.connected == true) {
            clearInterval(timer)
        }
        ws.conn();
    }, 100);
}

var onmessage = function (data) {

    let da = JSON.parse(data.data);
    if (da.ping != null) {
        ws.connction.send('{"pong":' + da.ping + '}')

    } else {
        Object.keys(subfun).forEach(function (key) {

            subfun[key](da)

        });



    }

}

ws.Sub = function (callback, name) {

    subfun[name] = callback
}

ws.UnSub = function (name) {

    delete subfun[name];
}
ws.sendData = function (data) {

    let str = JSON.stringify(data)
    if (this.connected == false) {
        ws.send(str)
    } else {
        let interval = setTimeout(() => {
            if (this.connected == true) {

                ws.connction.send(str)
                clearInterval(interval);
            }

        }, 10);
    }


}
ws.conn = function () {


    if (this.connected == false) {
        ws.connction = new WebSocket(wsuri);
        ws.connction.onopen = onopen
        ws.connction.onclose = onclose
        ws.connction.onmessage = onmessage
        ws.connected = true;

    }


}
export default ws;
