<template>
	<div>
		<nav-bar>{{ $t('team') }}</nav-bar>

		<div class="order-list">

			<div class="header">
				<li @click="chengeType(1)" :class="{ focus: status == 1 ? true : false }">{{ $t('level') }}-1</li>
				<li @click="chengeType(2)" :class="{ focus: status == 2 ? true : false }">{{ $t('level') }}-2</li>
				<li @click="chengeType(3)" :class="{ focus: status == 3 ? true : false }">{{ $t('level') }}-3</li>
			</div>
			<div class="lists">
				<div v-show="loading" class="loading">
					<van-loading type="spinner" color="#1989fa" />
				</div>

				<div class="direcct-list">
					<div class="info">

						<ul>
							<li>{{ info.people_num }}</li>
							<li>{{ $t('reg_num') }}</li>
						</ul>
						<ul>
							<li>{{ info.people_deposited }}</li>
							<li>{{ $t('recharge_num') }}</li>
						</ul>
						<ul>
							<li>{{ formatToPrice(info.deposit_amount) }}</li>
							<li>{{ $t('recharge_amount') }}</li>
						</ul>
						<ul>
							<li>{{ formatToPrice(info.payout_amount) }}</li>
							<li>{{ $t('payout_amount') }}</li>
						</ul>
					</div>
					<div class="list">
						<div v-for="(item, index)  in list" :key="index" class="list-item">
							<div class="list-info">
								<li>
									<i>{{ item.name }}</i>
								</li>
								<li>
									UID:
									<i class="code">{{ item.code }}</i>
								</li>
							</div>
							<div class="list-info">

								<li>
									Withdraw:
									<i>{{ formatToPrice(item.pay_out) }}</i>
								</li>
								<li>
									Join time:
									<i>{{ item.created_at }}</i>
								</li>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import AnimatedNumber from "animated-number-vue";
export default {
	name: "MarketList",
	components: { AnimatedNumber },
	data() {
		return {
			info: {},
			status: 1,
			loading: false,
			list: [],
			team: []
		};
	},
	created() {
		this.getList();
		this.getListPeople();
		//this.gettem();
	},

	methods: {
		gettem() {
			this.get("user/team").then(res => {
				this.team = res.data;
			});
		},
		getList() {
			this.loading = true;
			this.get("user/direct", { level: this.status }).then(res => {
				this.info = res.data;

				setTimeout(() => {
					this.loading = false;
				}, 100);
			});
		},
		getListPeople() {
			this.loading = true;
			this.get("user/direct_people", { level: this.status }).then(res => {
				this.list = res.data;

				setTimeout(() => {
					this.loading = false;
				}, 100);
			});
		},
		chengeType(status) {
			this.status = status;
			this.getList();
			this.getListPeople();
		},
		onClickLeft() {
			this.$router.go(-1);
		},
		formatToPrice(value) {
			return `₹${Number(value).toFixed(2)}`;
		}
	}
};
</script>

<style lang="scss" scoped>
.team {
	border: 1px solid #f2f2f2;
	margin: 1rem;
	border-radius: 1rem;

	background: #fff;
	padding: 2rem 0;

	.title {
		padding: 2rem;
		color: #000;
		font-size: 3rem;
	}

	.col {
		display: block;
		border-bottom: 1px solid #e2e2e2;

		div {
			font-size: 2rem;
			padding: 0.5rem 0;
			display: inline-block;
			width: 40%;

			&:first-child {
				padding-left: 2rem;
				color: rgb(133, 130, 130);
			}

			&:last-child {
				text-align: right;
				color: rgb(247, 150, 4);
			}
		}
	}
}

.lists {
	position: relative;
	height: 1px;

	.loading {
		display: block;
		position: absolute;
		background: #fff;
		width: 100vw;
		height: 80vh;
		text-align: center;
		line-height: 80vh;
		z-index: 10;
	}
}

.order-list {
	height: 93vh;
	overflow-y: scroll;
}

$call: #28baa7;
$put: #e44e69;

.header {
	display: flex;
	justify-content: space-evenly;
	font-size: 1.8rem;
	background: #fff;
	border-bottom: 1px solid #e9e8e7;
	padding: 2rem;

	li {
		padding: 1rem 3rem;
		border-radius: 1rem;

		list-style: none;
		color: #99a2b4;
		background: #f8f9fd;
		font-size: 1.7rem;

		transition: all 0.2s ease-in-out;
		-webkit-transition: all 0.2s ease-in-out;
		/* Safari */

		&.focus {
			background: #5e3de2;
			color: #fff;

			transition: all 0.2s ease-in-out;
			-webkit-transition: all 0.2s ease-in-out;
			/* Safari */
		}
	}
}

.direcct-list {
	.list {



		.list-item {
			display: flex;
			justify-content: space-between;

			padding: 1rem;
			border-bottom: 1px solid #f4f5f7;
			vertical-align: middle;



			.list-info {
				display: inline-block;
				padding: 1rem;

				vertical-align: middle;



				li {
					overflow: hidden;
					color: #e2e2e2;
					list-style: none;
					display: block;
					margin-top: 0.4rem;

					i {
						color: #646161;
						font-style: normal;

						&.code {
							color: #c1c4ca;
						}
					}
				}
			}
		}
	}

	.funds {
		font-size: 2.3rem;
	}

	background: #fff;
	height: 80vh;

	.info {
		background: #fff;
		border-bottom: 0.7rem solid #f5f6f7;
		padding-bottom: 2rem;

		.in_come {
			padding: 2rem;
			border-bottom: 2px solid #f5f6f7;
			color: #aeafaf;
			display: block;

			i {
				font-weight: bold;
				font-size: 1.8rem;
				font-style: normal;
			}
		}

		ul {
			margin-top: 2rem;
			list-style: none;
			display: inline-block;
			width: 50%;

			li {
				text-align: center;
				list-style: none;
				display: block;

				&:first-child {
					color: #000;
				}

				&:last-child {
					color: #aeafaf;
					font-size: 1.3rem;
					margin-top: 1rem;
				}
			}
		}
	}
}
</style>