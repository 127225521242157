<template>
    <div>
        <nav-bar>Order Detail:({{ data.title }} {{ data.created_at }})</nav-bar>
        <div class="detail">
            <div class="warp">
                <div class="item">
                    <li>{{ $t('product') }}:</li>
                    <li> <img :src="host + '/static/icon/' + data.title + '.png'" />{{ data.title }}</li>
                </div>
                <div class="item">
                    <li>{{ $t('path') }}:</li>
                    <li :class="{ red: data.path === 0 ? true : false, green: data.path === 0 ? false : true }">{{
                        data.path === 0 ? "CALL" : "PUT" }}</li>
                </div>
                <div class="item">
                    <li>{{ $t('open') }}:</li>
                    <li>{{ data.open_price }}</li>
                </div>
                <div class="item">
                    <li>{{ $t('close') }}:</li>
                    <li>{{ data.close_price }}</li>
                </div>
                <div class="item">
                    <li>{{ $t('time') }}:</li>
                    <li>1 minute</li>
                </div>
                <div class="item">
                    <li>{{ $t('status') }}:</li>
                    <li :class="{ red: data.status === 1 ? true : false, green: data.status === 2 ? true : false }">{{
                        getStatus(data.status) }}</li>
                </div>
                <div class="item">
                    <li>{{ $t('posotion') }}</li>
                    <li>₹{{ data.amount }}</li>
                </div>
                <div class="item">
                    <li>{{ $t('js') }}</li>
                    <li>₹{{ data.status == 1 ? data.profit_amount : 0 }}</li>
                </div>
                <div class="item">
                    <li>{{ $t('time') }}</li>
                    <li>{{ data.created_at }}</li>
                </div>
            </div>

        </div>






    </div>
</template>

<script>
import AnimatedNumber from "animated-number-vue";

export default {
    components: { AnimatedNumber },
    name: "AccountHis",
    data() {
        return {
            data: []
        };
    },
    created() {
        this.data = this.$route.params.data;



    },



    methods: {
        getStatus(status) {
            let status2 = "";
            switch (status) {
                case 0:
                    status2 = "Processing";
                    break;
                case 2:
                    status2 = "Loss";
                    break;
                case 1:
                    status2 = "Profit";
                    break;
            }
            return status2;
        },





    }
};
</script>

<style lang="scss" scoped>
$call: #28baa7;
$put: #e44e69;

.detail {
    background: #fff;
    padding: 2rem;

    .item {
        padding: 1rem 0;
        display: flex;
        justify-content: space-between;


        li {
            display: inline-block;
            vertical-align: middle;

            img {
                border-radius: 3rem;
                margin-right: 1rem;
                vertical-align: middle;
                width: 3rem;
                height: 3rem;
            }

            list-style: none;

            &:first-child {
                color: #99a2b4;
                font-size: 1rem;
            }

            &:last-child {
                color: #293348;
                font-size: 2rem;
                font-weight: 400;

            }

            &.red {
                color: $call;
            }

            &.green {
                color: $put;
            }
        }
    }

    .warp {}
}
</style>