module.exports = {
    welcome: "vaapas svaagat",
    siginaccount: "apane akaunt mein sain in karen",
    mob: "mobail phon nambar",
    pass: "paasavard",
    forgot_pass: "paasavard bhool gae",
    login: "daakhil karana",
    nothaveacccount: "khaata nahin hai",
    reg: "sain ap karen",
    create_account: "khaata banaen",
    get_free_account: "chalie phree kvaant treding akaunt ke saath shuruaat karate hain",
    email: "eemel",
    name: "poora naam",
    ref_code: "sandarbh kod",
    continue: "jaaree rakhana",
    have_account: "kya aapake paas pahale se ek khaata maujood hai",
    submit: "jama karana",
    otp: "OTP",
    resend: "pun: bhejen",
    deposit: "jama",
    payout: "nikaalana",
    team: "teem",
    service: "seva",
    total_asstes: "kul sampatti mooly",
    home: "ghar",
    market: 'baazaar',
    rewards: "puraskaar",
    wallet: "mujhe",
    serch: "khoj",
    invite_code: "sandarbh kod",
    copy_share: "link kopee karen aur sheyar karen",
    reward_list: "puraskaar rikord",
    share_words: "jab aamantrit upayogakarta richaarj karata hai, to inaam svachaalit roop se aapake khaate kee shesh raashi mein jud jaega",
    balance: "santulan",
    sarlry: "vetan santulan",
    verfed: "satyaapit",
    notverfd: "satyaapit nahin hai",
    fundlog: "khaata nipataan",
    sarlry_log: "perol rikords",
    fund_log: "vitteey rikord",
    invite_log: "nimantran inaam rikord",
    hist: "traanjekshan itihaas",
    idvef: "satyaapan kee pahachaan karen",
    acc_safe: "khaate kee suraksha",
    sigin_out: "sain aaut",
    recommand_amount: "anushansit raashi",
    active_balance: "upalabdh shesh raashi",
    taxs: "kar",
    total_amount: "kul raashi",
    payout_note: "nikaasee shulk 20% kar, nikaasee ka samay somavaar-shukravaar 7 poorvaahn-9 poorvaahn, 2, vetan nikaasee samay somavaar-ravivaar 7 poorvaahn-9 poorvaahn ",
    need_ver: "aapako pahale pahachaan satyaapan poora karana hoga",
    verf: "satyaapan kee pahachaan karen",
    not_bind_bank: "aapane abhee tak nakad nikaasee khaate ko baadhy nahin kiya hai, krpaya ise pahale baadhy karen",
    bind_bank: "baandhana",
    time: "samay",
    create: "banaen",
    pending: "baink mein lambit hai",
    refund: "dhanavaapasee",
    success: "saphalata",
    unpaid: "avaitanik",
    order_num: "aadesh sandarbh",
    trading: "vyaapaarik jode",
    traded: "itihaas",
    posotion: "pad",
    status: "darja",
    js: "samajhauta",
    product: "utpaad",
    path: "path",
    open: "udghaatan mooly",
    close: "band keemat",
    open2: "udghaatan mooly",
    close: "band keemat",
    pathhouer: "24H",
    market_detail: "बाजार विवरण",
    vol: "Vol",
    low: "kam",
    heigh: "uchch",
    call: "CALL",
    put: "PUT",
    rate: "signal dar",
    notice: "soochana",
    level: "star",
    reg_num: "panjeekrt kee kul sankhya",
    recharge_num: "richaarj karane vaalon kee kul sankhya",
    recharge_amount: "kul richaarj raashi",
    payout_amount: "kul nikaasee raashi",
    lang: "bhaasha"









}