<template>
    <div class="AccountHis">
        <nav-bar>{{ $t('invite_log') }}</nav-bar>


        <div v-show="loading" class="loading">
            <van-loading type="spinner" color="#1989fa" />
        </div>
        <div class="lists">
            <div ref="recharge">
                <div role="feed" class="van-list">
                    <div>
                        <div class="recharge-item" v-for="(item, index) in rechargeList" :key="index">
                            <div>
                                <div>
                                    <ul>
                                        <li class="tp">
                                        <li>
                                            invited:
                                            {{ item.code + "(" + item.name + ")"
                                            }}</li>
                                        <li class="amount">₹{{ item.amount }}</li>

                                    </ul>
                                    <ul>
                                        <li>{{ $t('time') }}:{{ item.created_at }}</li>
                                        <li class="status">
                                        </li>

                                    </ul>


                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="nomare">-No more -</div>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
export default {
    name: "AccountHis",
    data() {
        return {
            current: 0,


            rechargeList: [],
            withdrawalList: [],
            status: 0,
            loading: false
        };
    },
    created() {
        this.getRewardList();
    },

    methods: {

        getRewardList() {
            this.loading = true;

            this.get("reward/last_ten").then(res => {
                this.rechargeList = res.data;
                setTimeout(() => {
                    this.loading = false;
                }, 100);
            });
        },



    }
};
</script>

<style lang="scss" scoped>
.lists {
    background: #fff;
    height: 100vh;
    overflow-y: scroll;
}

.loading {
    display: block;
    position: absolute;
    background: #fff;
    width: 100vw;
    height: 80vh;
    text-align: center;
    line-height: 80vh;
    z-index: 10;
}

.header {
    margin-bottom: 1.5rem;

    font-weight: bold;
    font-size: 1.8rem;
    background: #fff;
    height: 6rem;
    line-height: 6rem;
    border-bottom: 1px solid #e9e8e7;

    li {
        display: inline-block;
        list-style: none;
        margin-left: 2rem;
        color: #6b6c6e;
        font-size: 1.7rem;

        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
        /* Safari */

        &.focus {
            font-size: 2.5rem;
            color: #000;

            transition: all 0.2s ease-in-out;
            -webkit-transition: all 0.2s ease-in-out;
            /* Safari */
        }
    }
}

p.success {
    color: #228b22 !important;
}

.recharge-item {
    padding: 1.5rem 1rem;

    border-bottom: 1px solid #f5f7f8;

    div {
        padding: 0;


        display: block;

        ul {
            display: block;
            padding: 0.5rem 1rem;

            li {
                &.amount {
                    float: right;
                    padding-right: 2rem;
                    color: #111729;
                    font-size: 2rem;
                }

                &.status {
                    text-align: right;
                    display: block;
                    padding-right: 2rem;
                    float: right;
                    color: #939090;


                }


                display: inline-block;

                &:first-child {
                    color: #cbcccd;

                    &.cr {
                        color: #5bbf63;
                    }

                    &.dr {
                        color: #d64080;
                    }
                }
            }









        }
    }
}

.nomare {
    color: #969799;
    font-size: 14px;
    line-height: 50px;
    text-align: center;
}
</style>