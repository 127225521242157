<template>
  <div>
    <div class="prize-container">
      <div class="top-container">
        <nav-bar>Ranking rewards</nav-bar>
      </div>
      <div v-if="selected == 0" class="prize-list-container">
        <div class="prize-list" v-for="(item, index) in team_week" :key="index">
          <div class="info">
            <div class="title">
              <img :src='topIcon[index] || topIcon[0]' alt="">
              <span>{{ item.pm }}</span>
            </div>
            <div class="desc">
              {{ item.title }} <span> Value:{{ item.value }}</span>
            </div>
          </div>
          <div class="prize-image">
            <img :src="item.prize" alt="">
          </div>
        </div>
      </div>
      <div v-if="selected == 1" class="prize-list-container">
        <div class="prize-list" v-for="(item, index) in team_month" :key="index">
          <div class="info">
            <div class="title">
              <img :src='topIcon[index] || topIcon[0]' alt="">
              <span>{{ item.pm }}</span>
            </div>
            <div class="desc">
              {{ item.title }} <span> Value:{{ item.value }}</span>
            </div>
          </div>
          <div class="prize-image">
            <img :src="item.prize" alt="">
          </div>
        </div>
      </div>
      <div v-if="selected == 2" class="prize-list-container">
        <div class="prize-list" v-for="(item, index) in l_week" :key="index">
          <div class="info">
            <div class="title">
              <img :src='topIcon[index] || topIcon[0]' alt="">
              <span>{{ item.pm }}</span>
            </div>
            <div class="desc">
              {{ item.title }} <span> Value:{{ item.value }}</span>
            </div>
          </div>
          <div class="prize-image">
            <img :src="item.prize" alt="">
          </div>
        </div>
      </div>
      <div v-if="selected == 3" class="prize-list-container">
        <div class="prize-list" v-for="(item, index) in l_month" :key="index">
          <div class="info">
            <div class="title">
              <img :src='topIcon[index] || topIcon[0]' alt="">
              <span>{{ item.pm }}</span>
            </div>
            <div class="desc">
              {{ item.title }} <span> Value:{{ item.value }}</span>
            </div>
          </div>
          <div class="prize-image">
            <img :src="item.prize" alt="">
          </div>
        </div>
      </div>


    </div>
    <div class="tabs">
      <div @click="selected = 0" :class="{ acc: selected == 0 }">Team Weekly</div>
      <div @click="selected = 1" :class="{ acc: selected == 1 }">Team Monthly</div>
      <div @click="selected = 2" :class="{ acc: selected == 2 }"> L1 Weekly </div>
      <div @click="selected = 3" :class="{ acc: selected == 3 }">L1 Monthly </div>

    </div>
  </div>


</template>
<script>

import top1Icon from '@/assets/ui5.0/prize/top1-icon.png'
import top2Icon from '@/assets/ui5.0/prize/top2-icon.png'
import top3Icon from '@/assets/ui5.0/prize/top3-icon.png'
import top4Icon from '@/assets/ui5.0/prize/top4-icon.png'
import prize0 from '@/assets/ui5.0/prize/prize0.png'
import prize1 from '@/assets/ui5.0/prize/prize1.png'
import prize2 from '@/assets/ui5.0/prize/prize2.png'
import prize3 from '@/assets/ui5.0/prize/prize3.png'
import prize4 from '@/assets/ui5.0/prize/prize4.png'
import prize5 from '@/assets/ui5.0/land.jpg'
export default {

  data() {
    return {
      selected: 1,
      activeIndex: 0,
      topIcon: [top1Icon, top2Icon, top3Icon, top4Icon],
      team_week: [
        { prize: prize0, title: "One Hyundai Motor", value: "1500k", pm: "Champion" },
        { prize: prize1, title: "Motorcycle(Bajaj pulsar 150cc)", value: "150k", pm: "Ranked 2-6" },
        { prize: prize2, title: "Iphone 15", value: "80k", pm: "Ranked 7-16" },
        { prize: prize3, title: "VIVO", value: "20k", pm: "Ranked 17-36" },
        { prize: prize4, title: "INR 5k", value: "5k", pm: "Ranked 37-86" },

      ],
      team_month: [
        { prize: prize5, title: "Land Rover Range Rover Evoque", value: "7000k", pm: "Champion" },
        { prize: prize0, title: "One Hyundai Motor", value: "1500k", pm: "Ranked 2-6" },
        { prize: prize1, title: "Motorcycle(Bajaj pulsar 150cc)", value: "150k", pm: "Ranked 7-16" },
        { prize: prize4, title: "INT 10k", value: "10k", pm: "Ranked 17-46" },


      ],
      l_week: [

        { prize: prize1, title: "Motorcycle(Bajaj pulsar 150cc)", value: "150k", pm: "Champion" },
        { prize: prize2, title: "Iphone 15", value: "80k", pm: "Ranked 2-3" },
        { prize: prize3, title: "VIVO", value: "20k", pm: "Ranked 4-6" },


      ],
      l_month: [
        { prize: prize0, title: "One Hyundai Motor", value: "1500k", pm: "Champion" },
        { prize: prize1, title: "Motorcycle", value: "150k", pm: "Ranked 2-6" },
        { prize: prize2, title: "Iphone 15", value: "80k", pm: "Ranked 7-16" },


      ]

    }
  },
  methods: {
    navPrizeRoute() {
      this.$router.push({ path: '/prize' })
    },
    concatImageUrl(index) {
      return `@/assets/ui5.0/prize/top${index + 1}-icon.png`
    }
  }
}
</script>
<style scoped lang="scss">
.tabs {
  background: #fff;
  width: 100vw;
  height: 5rem;


  position: fixed;
  display: flex;
  left: 0;
  bottom: 0;
  justify-content: space-around;
  border-top: 1px solid #f3f3f4;

  div {
    padding: 1rem 1rem;
    border-radius: 2rem 2rem 2rem 0rem;

    &.acc {
      font-weight: bold;
      background: #A42EFF;
      color: #fff;
      border-bottom: 2px solid #A42EFF;
    }

    text-align: center;
    line-height: 2rem;
  }

}

.prize-container {

  background: url("../../assets/ui5.0/prize/prize-bg.png") repeat top center;

  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;



  .prize-list-container {
    padding-top: 1rem;
    flex: 1;
    overflow: auto;

    .prize-list {
      display: flex;
      justify-content: space-between;
      padding: 1rem 1rem;
      margin: 0 1rem .5rem;
      align-items: center;
      background: #fff;
      border-radius: 6px;

      &:first-child {
        .prize-image {
          img {
            transform: translateX(-.8rem) scale(1.4);
          }
        }
      }

      .info {
        width: 60%;

        .title {
          margin-bottom: 1rem;
          display: flex;
          align-items: center;
          color: #000;

          img {
            width: 5.5rem;
            height: 5.5rem;
          }

          span {
            font-weight: 600;
            margin-left: .5rem;
          }
        }

        .desc {
          font-size: 1.7rem;
          color: #000;

          span {
            color: #f2a337;
            font-size: 1.3rem;

          }
        }
      }

      .prize-image {
        align-self: flex-end;

        img {
          width: 8.5rem;
          transform: scale(.9);

        }
      }
    }
  }

  .rules {
    background: #0054c9;
    height: 2.5rem;
    margin: 1rem 1rem;
    padding: .5rem 1rem;

    .rule-item {

      font-size: 1.7rem;
      color: #fff;
    }
  }
}
</style>