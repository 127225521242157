<template>
	<div class="top-warp">
		<div class="top-header">
			<div class="logo">
				<img src="../assets/imgs/logo.png" />
				{{ title }}
			</div>
			<div class="notif">
				<router-link to="/notify"><img src="../assets/bit/notification.png" /></router-link>



			</div>

		</div>

	</div>
</template>

<script>
export default {
	name: "sheader",
	props: ["title"],
	data() {
		return {

		};
	},
	created() {

	},

	methods: {


	}
};
</script>
<style lang="scss" scoped>
.top-warp {
	height: 6rem;
}

.top-header {
	display: flex;
	flex-wrap: wrap;


	justify-content: space-between;

	.logo {
		padding-left: 1rem;


		img {
			width: 3rem;
			height: 3rem;
			vertical-align: middle;
			border-radius: 3rem;


		}
	}

	.notif {
		padding-right: 3rem;


		width: 3rem;
		height: 3rem;

		img {
			margin-left: 1rem;
			vertical-align: middle;
			max-height: 3rem;
		}


	}

	border-bottom: 1px solid #f2f2f2;
	z-index: 1000;
	width: 100%;
	left: 0;
	top: 0;
	position: fixed;
	overflow: hidden;

	height: 6rem;
	line-height: 6rem;
	background: #fff;
	font-size: 2rem;
	font-weight: 700;
	color: #9d4747;



}
</style>
