<template>
	<canvas ref="line" :key="k" :style="{ height: '100%', width: '100vw' }"></canvas>
</template>

<script>
export default {
	name: "kline",
	props: { height: String, kdata: Array },
	mounted() {
		setTimeout(() => {
			this.k += 1;
			this.init();
			//this.kdata.push(Math.round(Math.random() * 800));
		}, 10);
	},
	data() {
		return {
			k: 0,

			w: 0,
			h: 0,
			dpr: false,
			ctx: null,
			max: 0,
			min: 0,
			l: 20,
			al: false
		};
	},

	created() {
		let $_this = this;
	},
	watch: {
		kdata(vol, oldvol) {
			this.k += 1;
			//this.datalen++;
			this.looping();
		}
	},

	methods: {
		Update() {
			//console.log("update2");
			this.k += 1;
			this.datalen++;
			this.l++;

			this.looping();
		},
		looping() {
			if (this.l < 50 && this.al == false) {
				this.init();
			} else {
				this.al = true;

				let looped = requestAnimationFrame(this.looping);
				if (this.l > 20) {
					// current 用来计算当前柱状的高度占最终高度的百分之几，通过不断循环实现柱状上升的动画
					this.l = this.l - 2 < 20 ? 20 : this.l - 2;
					this.init();
				} else {
					window.cancelAnimationFrame(looped);
					looped = null;
					this.l = 20;
					this.al = false;
				}
			}
		},
		init() {
			let $_this = this;
			this.$nextTick(() => {
				let canvas = $_this.$refs.line;

				let dpr =
					"devicePixelRatio" in window ? window.devicePixelRatio : 1; // 假设dpr为2

				// 获取css的宽高
				let {
					width: cssWidth,
					height: cssHeight
				} = canvas.getBoundingClientRect();
				// 根据dpr，扩大canvas画布的像素，使1个canvas像素和1个物理像素相等
				//canvas.style.width = canvas.width + "px";
				//canvas.style.height = canvas.height + "px";
				canvas.width = dpr * cssWidth;
				canvas.height = dpr * cssHeight;
				$_this.w = cssWidth;
				$_this.h = cssHeight;

				// 由于画布扩大，canvas的坐标系也跟着扩大，如果按照原先的坐标系绘图内容会缩小
				// 所以需要将绘制比例放大
				let ctx = canvas.getContext("2d");
				ctx.translate(0.5, 0.5);
				ctx.scale(dpr, dpr);
				//ctx.scale(scale, scale);
				this.ctx = ctx;
				$_this.draw(ctx);
			});
		},
		drow_kd(c, max, min) {
			c.lineWidth = 1;
			c.strokeStyle = "#e3e8ef";
			let step = this.h / 6;
			let step2 = this.w / 6;
			let inter = (this.max - this.min) / 6;
			c.setLineDash([3, 3]);
			for (let i = 0; i < 6; i++) {
				c.fillStyle = "#ffffff";
				c.textBaseline = "bottom";
				c.fillText(
					(this.min + inter * i).toFixed(2),
					this.w - 50,
					step * i - 2
				);
				c.moveTo(0, step * i);
				c.lineTo(this.w, step * i);
			}
			for (let i = 0; i < 6; i++) {
				c.moveTo(step2 * i, 0);
				c.lineTo(step2 * i, this.h);
			}
			c.stroke();
			c.beginPath();
			c.lineWidth = 2;
			c.setLineDash([]);
			c.strokeStyle = "#d54080";
		},

		draw(c) {
			console.log(this.l);
			//c.lineWidth = 3;
			c.strokeStyle = "#ffffff";
			c.lineCap = "round";
			delete this.kdata[0];
			let max_arr = [];

			for (
				let i = this.kdata.length - this.l;
				i < this.kdata.length;
				i++
			) {
				if (typeof this.kdata[i] === "undefined") {
					continue;
				}

				max_arr.push(this.kdata[i].close);
			}
			//c.beginPath();
			let max = Math.max.apply(Math, max_arr);
			let min = Math.min.apply(Math, max_arr);
			max = max + max * 0.0001;
			min = min - min * 0.0001;
			this.max = max;
			this.min = min;

			this.drow_kd(c, max, min);
			let gradient = c.createLinearGradient(-500, 0, 300, 500);
			gradient.addColorStop(0, "rgba(106, 213, 225, 0.3)");
			gradient.addColorStop(1, "rgba(106, 213, 225, 0.1)");
			c.fillStyle = gradient; //
			let x_interval = (this.w - 20) / 60;
			let y_interval = (this.h - 20) / (max - min);
			//console.log(max, min);
			//console.log(max, min);

			let lasti = 0;
			let lastpos = 0;

			for (
				let i = this.kdata.length - this.l;
				i < this.kdata.length;
				i++
			) {
				if (typeof this.kdata[i] === "undefined") {
					continue;
				}
				let data = this.kdata[i].close - min;
				let y = this.h - data * y_interval;

				lastpos = y;
				if (lastpos === 0) {
					c.moveTo(0, y);
				} else {
					c.lineTo(lasti * x_interval, y);
				}
				lasti++;
			}

			c.stroke();

			c.lineTo(lasti * x_interval, this.h);
			c.lineTo(0, this.h);
			//c.closePath();
			//c.fill(); //填充
			//c.clearRect(0, 0, 500, 500);
			//c.fillStyle = "#016bf9";
			c.strokeStyle = "#e3e8ef";
			//c.fillStyle = "#516c90";
			c.beginPath();

			c.moveTo(0, lastpos);
			c.lineTo(this.w - 80, lastpos);
			c.stroke();
			c.beginPath(); //没有
			c.fillStyle = "rgba(41, 185, 166,0.2)";
			c.arc(lasti * x_interval, lastpos, 10, 0, Math.PI * 2, false);
			c.fill(); //填
			c.beginPath(); //没有
			c.fillStyle = "rgba(41, 185, 166,0.5)";
			c.arc(lasti * x_interval, lastpos, 6, 0, Math.PI * 2, false);
			c.fill(); //填
			c.beginPath(); //没有
			c.fillStyle = "#e3e8ef";
			c.arc(lasti * x_interval, lastpos, 3, 0, Math.PI * 2, false);
			c.fill(); //填
			/**三角 */
			c.fillStyle = "#e3e8ef";
			c.beginPath();
			c.moveTo(this.w - 85, lastpos); //起点
			c.lineTo(this.w - 80, lastpos + 5); //第二点
			c.lineTo(this.w - 80, lastpos - 5); //第三点
			c.fill(); //填
			/**三角结束 */
			c.fillStyle = "#e3e8ef";
			c.fillRect(this.w - 80, lastpos - 10, 80, 20);
			c.fillStyle = "#ffffff";
			c.font = '14px "微软雅黑"';
			c.fillText(
				this.kdata[this.kdata.length - 1].close,
				this.w - 70,
				lastpos + 10
			);

			//绘制
		}
	}
};
</script>

<style lang="scss" scoped></style>