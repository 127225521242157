<template>
	<div class="change-pass">
		<van-nav-bar title="Modify login password" left-arrow @click-left="onClickLeft" />
		<div class="safe">
			<base-form ref="repass">
				<div class="item">
					<base-input
						type="password"
						v-model="form.old_pass"
						maxlength="20"
						:roule="{reg:'^[0-9,a-z,A-Z,\.,\*,@]{6,20}',msg:'Invild Password'}"
						placeholder="Old Password"
					></base-input>
				</div>
				<div class="item">
					<base-input
						type="password"
						v-model="form.new_pass"
						maxlength="20"
						:roule="{reg:'^[0-9,a-z,A-Z,\.,\*,@]{6,20}',msg:'Invild Password'}"
						placeholder="New Password"
					></base-input>
				</div>
				<div class="item">
					<base-input
						type="password"
						v-model="form.comfirm_pass"
						maxlength="20"
						:roule="{reg:'^[0-9,a-z,A-Z,\.,\*,@]{6,20}',msg:'Invild Password'}"
						placeholder="Confirm Password"
					></base-input>
				</div>
			</base-form>
			<div class="item">
				<base-btn @click="repass" :loading="loading">Submit</base-btn>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "AccountHis",
		data() {
			return {
				loading: false,
				form: {
					old_pass: "",
					new_pass: "",
					comfirm_pass: ""
				}
			};
		},
		created() {},
		mounted() {},
		methods: {
			repass() {
				if (this.$refs.repass.submit() == false) {
					return;
				}
				this.loading = true;
				this.post("user/reset_login_pass", this.form).then(res => {
					this.$toast(res.info);
					this.loading = false;
				});
			},
			onClickLeft() {
				this.$router.go(-1);
			}
		}
	};
</script>

<style lang="scss" scoped>
.change-pass {
	background: #fff;
	height: 100vh;
}
.safe {
	margin-top: 10rem;
	.item {
		display: block;
		margin: 0.5rem 2rem;

		overflow: hidden;
	}
}
</style>