<template>
	<div class="bank">
		<van-nav-bar title="BANK TRANSFER" left-arrow @click-left="onClickLeft" />
		<div class="payment">
			<div class="paying">
				<div>Your are paying</div>
				<div>
					₹{{ amount
					}}
				</div>
			</div>
			<div class="bank-details">
				<div class="title">Touch to Copy,If using bank transfer, please select IMPS</div>
				<div v-if="bank.upi.length > 2">
					<div class="title2">UPI Transfer</div>
					<div @click="copy" :data-clipboard-text="bank.upi" class="row capybut">
						<div>UPI:</div>
						<div class="info upi">{{ bank.upi }}</div>
					</div>
				</div>

				<div class="title2">Bank Transfer</div>
				<div @click="copy" class="row capybut" :data-clipboard-text="bank.name">
					<div>Holder Name:</div>
					<div class="info">{{ bank.name }}</div>
				</div>
				<div @click="copy" :data-clipboard-text="bank.account" class="row capybut">
					<div>Account no:</div>
					<div class="info">{{ bank.account }}</div>
				</div>
				<div @click="copy" :data-clipboard-text="bank.ifsc" class="row capybut">
					<div>IFSC:</div>
					<div class="info">{{ bank.ifsc }}</div>
				</div>
				<div class="pay-btn">
					<base-btn :loading="loading" @click="getShare">Sending screenshots</base-btn>
				</div>
				<div class="note">Once you have successfully transferred the funds, please send a screenshot of the transfer
					details to the recharge agent</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Dialog } from "vant";
import Clipboard from "clipboard";
export default {
	name: "Recharge",
	data() {
		return {
			amount: 0,
			code: "",
			orderid: "",
			bank: [],
			wa: "",
			tg: "",
		};
	},
	created() {
		this.amount = this.$route.query.amount;
		this.code = this.$route.query.code;
		this.orderid = this.$route.query.orderid;
		this.getbank();
		this.getInfo();
	},


	methods: {
		getInfo() {
			this.get("user/service").then(res => {
				this.wa = res.data.wa;
				this.tg = res.data.tg;
			});
		},
		getbank() {
			this.get("pay/bank").then(res => {
				this.bank = res.data;
			});
		},
		copy() {
			// 复制邀请码
			var clipboard = new Clipboard(".capybut");
			clipboard.on("success", e => {
				Dialog.alert({
					message: "Copied successfully"
				}).then(() => {
					clipboard.destroy(); // 释放内存
				});
			});
			clipboard.on("error", e => {
				//Toast("该浏览器不支持自动复制");
				clipboard.destroy(); // 释放内存
			});
		},
		getShare() {
			let link = "";
			let base = "https://api.whatsapp.com/send?phone="
			let urls = this.wa.toString().split(",");
			if (urls.length > 1) {
				let random = Math.abs(
					Math.round(Math.random() * urls.length - 1)
				);
				console.log(urls[random], random, urls.length - 1);
				link = urls[random];
			} else {
				link = this.wa;
			}

			let msg =
				"I have successfully transferred:" +
				this.amount +
				",please confirm,ID:" +
				this.code +
				",ORDER ID:" +
				this.orderid;
			let url = base + link + "&text=" + msg;



			window.location.href = url;

		},
		onClickRight() {
			this.$router.push("./hist");
		},

		onClickLeft() {
			this.$router.go(-1);
		}
	}
};
</script>

<style lang="scss" scoped>
.payment {
	background: #fff;
	color: #000;
	padding: 2rem;
	height: 80vh;

	.paying {
		div {
			display: block;
			padding: 1rem 0;

			&:first-child {
				color: rgb(141, 138, 138);
			}

			&:last-child {
				font-size: 3rem;
				color: #000;
			}
		}
	}

	.bank-details {
		.title2 {
			padding: 2rem 0;
			display: block;
			color: #000;
			font-weight: bold;
			border-bottom: 1px solid #7c7a7a;
		}

		padding-top: 2rem;

		.title {
			padding-left: 1rem;
			color: rgb(250, 4, 4);
		}

		.note {
			background: #faf9df;
			color: rgb(245, 180, 0);
			padding: 2rem;
			border: 1px dashed #d1ce01;
			line-height: 180%;
			font-size: 2rem;
			border-radius: 1rem;
			margin-top: 1rem;
		}

		.row {
			display: block;
			padding: 0.2rem 0;
			border-bottom: 1px solid #f2f2f2;

			div {
				display: inline-block;
				padding: 1rem;

				&:first-child {
					vertical-align: middle;
					width: 10rem;
					color: rgb(141, 138, 138);
				}

				&.upi {
					color: rgb(199, 11, 11) !important;
				}

				&.info {
					color: #000;
					font-weight: bold;
					font-size: 2rem;
				}
			}
		}
	}
}
</style>