<template>
    <div>
        <nav-bar>{{ data.title }}</nav-bar>
        <div class="notify">

            <div class="content" v-html="data.contents"></div>

        </div>



    </div>
</template>

<script>
import AnimatedNumber from "animated-number-vue";

export default {
    components: { AnimatedNumber },
    name: "AccountHis",
    data() {
        return {
            loading: false,
            data: "",
            id: ""


        };
    },
    created() {
        this.id = this.$route.query.id;


        this.list();



    },



    methods: {

        list() {
            this.get("notice/info", { id: this.id }).then(res => {
                this.data = res.data

            })
        }


    }
};
</script>

<style lang="scss">
.notify {
    background: #fff;
    min-height: 100vh;
    padding: 2rem;


    .content {
        p {
            line-height: 180%;
            color: #a09f9f !important;
        }
    }


}
</style>
