<template>
	<div>
		<van-nav-bar title="Settings" left-arrow @click-left="onClickLeft" />
		<div class="safes">
			<li @click="logout">
				Log out
				<img src="../../assets/imgs/profile/right.png" class="profile-sp-right" />
			</li>
		</div>
	</div>
</template>

<script>
import axios from "axios";
export default {
	name: "AccountHis",
	data() {
		return {
			current: 0
		};
	},
	created() { },
	mounted() { },
	methods: {
		logout() {
			let code = localStorage.getItem("mob");
			localStorage.clear();
			localStorage.setItem("mob", code);

			this.$router.push("/login");
		},
		onRead(fiels) {
			let config = {
				"Content-Type": "multipart/form-data",
				token: localStorage.getItem("token")
			};
			axios.defaults.headers = config;
			let fd = new FormData();
			fd.append("face_icon", fiels.file);
			axios
				.post(this.host + "user/set_face_icon", fd, config)
				.then(res => {
					console.log(res);
				});
		},
		onClickLeft() {
			this.$router.go(-1);
		}
	}
};
</script>

<style lang="scss" scoped>
.safes {
	li {
		img {
			width: 0.875rem;
			height: 1.625rem;
			float: right;
		}

		list-style: none;
		display: block;
		padding: 2rem 1rem;
		background: #fff;
		margin-top: 0.2rem;
	}
}
</style>