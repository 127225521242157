<template>
    <div>
        <nav-bar>Online Service</nav-bar>
        <iframe :src="link"></iframe>
    </div>
</template>

<script>
export default {
    name: "MarketList",
    data() {
        return {
            code: "",
            channel: "",
            link: ""

        };
    },
    created() {
        this.code = this.$route.query.code;
        this.channel = this.$route.query.channel;
        this.link = "https://chat.livechats.chat/?channelId=" + this.channel + "&customer={'UID':" + this.code + "}&uniqueId=" + this.code

    },

    methods: {


    }
};
</script>

<style lang="scss" scoped>
iframe {
    width: 100vw;
    height: 92vh;
    border: none;
}
</style>